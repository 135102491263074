import { ComponentController } from 'Common/components'
import { groupBy } from 'lodash'

class EquipmentsController extends ComponentController {
  get isEquipmentsLoaded() {
    const { appState, store } = this.props
    const { stateKey } = store
    const { stateKey: stateSkillKey } = this.expertiseSkillStore
    const equipments = appState.getIn([stateKey, 'list'])
    const skills = appState.getIn([stateSkillKey, 'list'])

    return !!skills && !!equipments
  }

  get expertiseSkillStore() {
    return this.props.stores.get('expertise')
  }

  get expertiseSkill() {
    return this.props.appState.getIn(['admin.Expertise', 'list'])
  }

  get equipmentGroups() {
    const { appState, store } = this.props
    const { stateKey } = store
    return appState.getIn([stateKey, 'list']).filter((equipment) => !equipment.parentId)
  }

  getEquipments() {
    const { appState, store } = this.props
    const isApiInitialized = store.isApiInitialized(appState)
    if (!isApiInitialized) {
      return {}
    }
    const { search, stateKey } = store.getAdminInfo()
    const { state: { query } = {} } = this.component
    const equipments = search ? store.filter(appState, search.field, query) : appState.getIn([stateKey, 'list'])
    const groupedSkills = groupBy(this.expertiseSkill, 'groupId')
    const equipmentItems = equipments.filter((equipment) => equipment.parentId !== undefined)
    const equipmentGroupIds = new Set()
    const groupedEquipments = equipmentItems.reduce((memo, equipment) => {
      const { parentId, id } = equipment
      const parentGroup = this.equipmentGroups.find((group) => group.id === parentId)
      if (!parentGroup) {
        return memo
      }
      equipmentGroupIds.add(parentId)
      const { title: groupName, id: groupId } = parentGroup
      const skills = groupedSkills[id]
      const skillNames = skills ? skills.map((skill) => skill.name).join() : ''
      const equipmentExtended = { ...equipment, groupId, groupName, skillNames }
      return memo.concat(equipmentExtended)
    }, [])
    const emptyGroups =
      search && query
        ? []
        : Object.values(this.equipmentGroups)
            .filter((group) => !equipmentGroupIds.has(group.id))
            .map((group) => ({ groupId: group.id, groupName: group.title }))
    return {
      groupedEquipments,
      emptyGroups
    }
  }

  loadData() {
    const { store } = this.props
    store.loadDataAction({ type: 'equipment' })
    this.expertiseSkillStore.loadDataAction({ type: 'equipment' })
  }

  getEquipment(id) {
    const { appState, store } = this.props
    const { stateKey } = store
    const expertises = appState.getIn([stateKey, 'list'])
    return expertises.find((expertise) => expertise.id === id)
  }

  getSkills(groupId) {
    const { appState } = this.props
    const { stateKey: stateSkillKey } = this.expertiseSkillStore
    const skills = appState.getIn([stateSkillKey, 'list'])
    return skills ? skills.filter((skill) => skill.groupId === groupId) : []
  }

  async createEquipment(form) {
    const { store, appState } = this.props
    const { parentId, title, description, skills, imageUrl } = form
    const {
      createExpertiseGroup: { id: equipmentId }
    } = await store.createExpertiseSubgroup(appState, parentId, title, description, imageUrl, 'equipment')
    await this.expertiseSkillStore.createExpertises(equipmentId, skills, 'equipment')
  }

  async updateSkills(form, equipmentId, currentSkills) {
    const { skills = [] } = form
    const currentSkillNames = currentSkills.map((item) => item.name)
    const skillsToRemove = currentSkills.filter((skill) => !skills.includes(skill.name))
    const newSkills = skills.filter((skillName) => !currentSkillNames.includes(skillName))
    await this.expertiseSkillStore.createExpertises(equipmentId, newSkills, 'equipment')
    skillsToRemove.forEach((skill) => {
      return this.expertiseSkillStore.deleteExpertise(skill.id)
    })
  }

  createEquipmentGroup(form) {
    const { store } = this.props
    const { title } = form
    return store.createExpertiseGroup(title, 'equipment')
  }

  deleteEquipment(id) {
    const { store } = this.props
    return store.deleteExpertiseGroup(id, 'equipment')
  }

  deleteEquipmentGroup(id) {
    const { store } = this.props
    return store.deleteExpertiseGroup(id, 'equipment')
  }
}

export default EquipmentsController
