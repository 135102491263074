import { Component } from 'react'
import OpenShiftStatus from './OpenShiftStatus'
import OpenShiftActivity from './OpenShiftActivity'
import { OpenShiftEntity } from 'entityWrappers'
import { navigate } from 'Navigation'
import classNames from 'classnames'

export default class OpenShift extends Component {
  render() {
    const { openShift, compensateCol, unitRoles, weekDay, day, month } = this.props
    const openShiftEntity = new OpenShiftEntity(openShift)
    const { createdAtDateTime, time, staffMismatch, isPosted, pendingToConfirmUserIds, isFilled } = openShiftEntity

    const openShiftStatusProps = {
      isPosted,
      pendingToConfirmUserIds,
      isFilled,
      openShift,
      staffMismatch
    }
    const roleId = openShift.get('unitRoleId')
    const role = unitRoles.find((role) => role.get('id') === roleId)
    const roleName = role.get('name')
    const humanizedTime = openShift.get('humanizedTime')
    const isImportant = openShift.get('isImportant')

    const openShiftActivityProps = { openShift }

    return (
      <div
        className={classNames('hx-open-shift-item-week bg-white', {
          'bg-important': !!isImportant
        })}
        onClick={this.navigateToOpenShift(openShiftEntity)}
      >
        <div className="semibold col-2 hx-cards-date">
          {!compensateCol && (
            <div className="card-date rounded bg-porcelain">
              <span className="icon icon-calendar" />
              {month} {day}, {weekDay}
            </div>
          )}
        </div>
        <div className="trout semibold col-1">{humanizedTime}</div>
        <div className="col-1 card-time">{time}</div>
        <div className="col-3">{roleName}</div>
        <div className="text-center semibold col-1">{Math.max(staffMismatch, 0)}</div>
        <OpenShiftStatus {...openShiftStatusProps} />
        <OpenShiftActivity {...openShiftActivityProps} />
        <div className="semibold col-2">{createdAtDateTime}</div>
        <div className="important-icon">
          {!!isImportant && <div className="icon icon-Exclamation-Point red-orange border-redorange" />}
        </div>
      </div>
    )
  }

  navigateToOpenShift = (openShiftEntity) => () => {
    const { isPosted, id, shiftDayId } = openShiftEntity

    if (isPosted) {
      navigate.from.OpenShifts.to.ShiftOpportunity({ id })
    } else {
      navigate.from.OpenShifts.to.OpenShift({ shiftDayId })
    }
  }
}
