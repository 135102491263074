import { paths } from 'Navigation'
import { Link } from 'react-router-dom'
import { keyBy } from 'utils'
import SwitchFacilityList from './SwitchFacilityList'
import { t } from 'i18n'
import constants from 'utils/constants'

const { digitalServicesActLink } = constants

export default function DropdownList({ authStore, appState, switchFacilityRedirectTo, className = '' }) {
  const auth = appState.get('authentication')
  const facilities = auth.get('facilities')
  const currentFacilityUrlId = auth.getIn(['facility', 'urlId'])
  const facilityNamesByUrlId = keyBy(facilities, 'urlId').map((f) => f.get('name'))
  const hasMultipleFacilities = facilityNamesByUrlId.size > 1

  const switchFacilityProps = {
    redirectToPathname: switchFacilityRedirectTo,
    currentFacilityUrlId,
    facilityNamesByUrlId,
    authStore
  }

  return (
    <ul className={`bg-brightgray text-left ${className}`}>
      <li>
        <Link to={paths.PrivacyPolicy} className="_privacy bold smaller upper">
          {t('privacy_policy_text')}
        </Link>
      </li>
      <li>
        <Link to={paths.TermsOfService} className="_termsOfService bold smaller upper">
          {t('terms_of_service_text')}
        </Link>
      </li>
      <li>
        <Link to={paths.ReadmeOSS} className="_readmeoss bold smaller upper">
          {t('readme_oss_text')}
        </Link>
      </li>
      <li>
        <a
          className="_digitalServicesAct bold smaller upper"
          href={digitalServicesActLink}
          target="_blank"
          rel="noreferrer"
        >
          {t('digital_service_act_text')}
        </a>
      </li>
      {hasMultipleFacilities && <SwitchFacilityList {...switchFacilityProps} />}
      <li>
        <Link to={paths.Logout} className="_logout bold smaller upper">
          {t('auth.logout')}
        </Link>
      </li>
    </ul>
  )
}
