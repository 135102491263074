import { curry } from 'i18n'

const t = curry('openShifts.cards.header.outer.')

export default function OpenShiftCardHeader({ classNames }) {
  const headerClassNames = 'row bg-white hx-open-shift-cards-outer-header'

  return (
    <div className={headerClassNames}>
      <div id={`${classNames}-${t('shiftDate')}`} className="col-2">
        {t('shiftDate')}
      </div>
      <sh-tooltip
        label={t('shiftDate')}
        target={`${classNames}-${t('shiftDate')}`}
        placement="bottom"
        variation="short"
      />
      <div id={`${classNames}-${t('shift')}`} className="col-1">
        {t('shift')}
      </div>
      <sh-tooltip label={t('shift')} target={`${classNames}-${t('shift')}`} placement="bottom" variation="short" />
      <div id={`${classNames}-${t('time')}`} className="col-1">
        {t('time')}
      </div>
      <sh-tooltip label={t('time')} target={`${classNames}-${t('time')}`} placement="bottom" variation="short" />
      <div id={`${classNames}-${t('role')}`} className="col-3">
        {t('role')}
      </div>
      <sh-tooltip label={t('role')} target={`${classNames}-${t('role')}`} placement="bottom" variation="short" />
      <div id={`${classNames}-${t('openNeeded')}`} className="col-1">
        {t('openNeeded')}
      </div>
      <sh-tooltip
        label={t('openNeeded')}
        target={`${classNames}-${t('openNeeded')}`}
        placement="bottom"
        variation="short"
      />
      <div id={`${classNames}-${t('status')}`} className="col-3">
        {t('status')}
      </div>
      <sh-tooltip label={t('status')} target={`${classNames}-${t('status')}`} placement="bottom" variation="short" />
      <div id={`${classNames}-${t('activity')}`} className="col-4">
        {t('activity')}
      </div>
      <sh-tooltip
        label={t('activity')}
        target={`${classNames}-${t('activity')}`}
        placement="bottom"
        variation="short"
      />
      <div id={`${classNames}-${t('created')}`} className="col-3">
        {t('created')}
      </div>
      <sh-tooltip label={t('created')} target={`${classNames}-${t('created')}`} placement="bottom" variation="short" />
    </div>
  )
}
