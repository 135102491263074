import { PureComponent } from 'react'
import { pick } from 'lodash'
import Header from './Header'
import OpenShiftRequestDetails from './OpenShiftRequestDetails'
import OpenShiftStaff from './OpenShiftStaff/'
import OpenShiftController from './OpenShiftController'
import './OpenShift.scss'

class OpenShift extends PureComponent {
  constructor(props) {
    super(props)
    this.openShiftController = new OpenShiftController(this)
    this.state = this.openShiftController.defaultState
  }

  render() {
    const { unit } = this.props
    const unitShiftTypes = unit.get('shiftTypes')
    const roles = unit.get('roles')

    const openShiftStaffProps = pick(
      {
        ...this.props,
        unitShiftTypes,
        roles,
        openShiftController: this.openShiftController
      },
      [
        'unitShiftTypes',
        'eligibleUnits',
        'timeService',
        'openShift',
        'staffConfig',
        'staffFilter',
        'openShiftStaffMap',
        'openShiftController',
        'openShiftParameters',
        'openShift',
        'roles',
        'expertises'
      ]
    )

    const headerProps = pick(
      {
        ...this.props,
        openShiftController: this.openShiftController
      },
      ['openShiftController', 'openShiftStaffMap', 'openShift']
    )

    const openShiftRequestDetailsProps = pick(
      {
        ...this.props,
        openShiftController: this.openShiftController
      },
      ['unit', 'openShift', 'openShiftStaffMap', 'openShiftParameters', 'openShiftController', 'timeService']
    )

    return (
      <>
        <Header {...headerProps} />
        <div className="pt25">
          <OpenShiftRequestDetails {...openShiftRequestDetailsProps} />
          <OpenShiftStaff {...openShiftStaffProps} />

          <div className="hx-open-shift-request-details-container">
            <div className="crew-place-footer">
              <h4 className="inverted">Request a healthcare worker from</h4>
              <a
                className="button"
                href="https://www.siemens-healthineers.com/en-us/education/workforce-planning/crewplace"
                target="_blank"
                rel="noreferrer"
              >
                <h4 className="inverted">CrewPlace</h4>
                <i className="icon icon-link" />
              </a>
              <h4 className="inverted">to fill this shift!</h4>
            </div>
          </div>
        </div>
      </>
    )
  }

  async componentDidMount() {
    const { activeDateRange } = this.props
    const isActiveDateRangeReady = activeDateRange.get('isReady')

    if (isActiveDateRangeReady) {
      this.loadOpenShift()
    }

    this.openShiftController.initializeFilters()
  }

  componentDidUpdate(prevProps) {
    const { openShift, activeDateRange } = this.props
    const { openShift: prevOpenShift } = prevProps
    const isOpenShiftChanged = prevOpenShift !== openShift
    if (isOpenShiftChanged) {
      this.openShiftController.openShift = openShift
    }
    const { isReady, isLoading } = this.openShiftController
    const isActiveDateRangeReady = activeDateRange.get('isReady')

    if (!isReady && !isLoading && isActiveDateRangeReady) {
      this.loadOpenShift()
    }
  }

  async loadOpenShift() {
    const { id, shiftDayId, unit, activeDateRange, openShiftsStore } = this.props
    const shifts = unit.get('shifts')
    const shiftsById = shifts.groupBy((shift) => shift.get('id'))
    const isOpenShift = !id
    const isShiftOpportunity = !!id
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')

    if (isOpenShift) {
      this.openShiftController.isLoading = true
      openShiftsStore.resetOpenShift()
      await openShiftsStore.loadOpenShift(shiftDayId, facilityUsersMap, shiftsById)
      this.openShiftController.isLoading = false
    }

    if (isShiftOpportunity) {
      this.openShiftController.isLoading = true
      openShiftsStore.resetOpenShift()
      await openShiftsStore.loadShiftOpportunity(id, facilityUsersMap, shiftsById)
      this.openShiftController.isLoading = false
    }
  }
}

export default OpenShift
