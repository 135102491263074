import { NOTIFICATION_TYPES } from "Staff/Calendar/utils";
import { NotificationItem } from "Staff/Common/NotificationItem";

const NotificationCard = ({ timeOffRequestStatus, onClose  }: any) => {
    let notificationContent;
  
    switch (timeOffRequestStatus) {
      case 'Success':
        notificationContent = (
          <NotificationItem
            labelKey="staffCalendar.timeOff.notification.successLabel"
            message="Your time off request has been sent for your manager approval."
            type={NOTIFICATION_TYPES.SUCCESS}
            onClose={onClose}
          />
        );
        break;
      case 'Failure':
        notificationContent = (
          <NotificationItem
            labelKey="staffCalendar.timeOff.notification.errorLabel"
            message="Error raising time off request."
            type={NOTIFICATION_TYPES.FAILURE}
            onClose={onClose}
          />
        );
        break;
      default:
        notificationContent = null;
    }
  
    return <div className="notification-container">{notificationContent}</div>;
  }

export default NotificationCard