import { List, Map } from 'immutable'
import { OpenShiftStaff } from 'entityWrappers'
import StaffPreferences from '../../../entityWrappers/StaffPreferences'
import { OpenShiftStaffRequirementsService } from '../../../services'
import OpenShiftEntity from '../../../entityWrappers/OpenShiftEntity'

export default function buildOpenShiftStaffMap(openShift, filter, config, timeService, expertises) {
  const approvedStaff = openShift.get('approvedFacilityUsers')
  const notRespondedStaff = openShift.get('notRespondedFacilityUsers')
  const declinedStaff = openShift.get('declinedFacilityUsers')
  const acceptedStaff = openShift.get('acceptedFacilityUsers')
  const facilityUsers = openShift.get('facilityUsers')
  const notInvitedFacilityUsers = openShift.get('notInvitedFacilityUsers')
  const dateTime = openShift.get('shiftStartsAt')
  const expertiseRequirements = openShift.get('expertiseRequirements')
  const expertiseItems = expertises ? expertises.get('expertiseItems') : List()

  const notInvitedStaff = extendAndFilterStaff({
    openShift,
    staff: notInvitedFacilityUsers,
    expertiseItems,
    expertiseRequirements,
    dateTime,
    filter,
    config,
    timeService
  })

  const eligibleStaff = extendAndFilterStaff({
    openShift,
    staff: facilityUsers,
    expertiseItems,
    expertiseRequirements,
    dateTime,
    filter,
    config,
    timeService
  })

  return Map({
    approvedStaff,
    notRespondedStaff,
    declinedStaff,
    acceptedStaff,
    notInvitedStaff,
    eligibleStaff
  })
}

function extendAndFilterStaff({
  openShift,
  staff,
  expertiseItems,
  expertiseRequirements,
  dateTime,
  filter,
  config,
  timeService
}) {
  const hiddenUserIds = config.get('hiddenUserIds')
  const selectedUserIds = config.get('selectedUserIds')

  const extendedStaff = staff.map((staff) => {
    const userId = staff.get('userId')
    const isSelected = selectedUserIds.includes(userId)

    const facilityProfile = staff.get('facilityProfile') || Map()
    const firstName = facilityProfile.get('firstName')
    const lastName = facilityProfile.get('lastName')
    const fullName = `${firstName} ${lastName}`

    return staff.merge({ isSelected, fullName })
  })
  const visibleStaff = getVisibleStaff(extendedStaff, hiddenUserIds)
  const sortedStaff = sortStaff(visibleStaff)
  return filterStaff(openShift, sortedStaff, expertiseItems, expertiseRequirements, filter, dateTime, timeService)
}

function getVisibleStaff(staff, hiddenUserIds) {
  return staff.filter((staff) => {
    const userId = staff.get('userId')
    return !hiddenUserIds.includes(userId)
  })
}

function sortStaff(staff) {
  const SORT_INDEX = { fullTime: 1, partTime: 2, perDiem: 3 }
  const getEmploymentTypeSortIndex = (type) => SORT_INDEX[type] || 4

  return staff.sortBy((staff) => {
    const firstName = staff.getIn(['profile', 'firstName'])
    const lastName = staff.getIn(['profile', 'lastName'])
    const employmentType = staff.getIn(['staffProfile', 'employmentType'])
    const sortIndex = getEmploymentTypeSortIndex(employmentType)

    const fullName = `${firstName} ${lastName}`
    return `${sortIndex} ${employmentType} ${fullName}`
  })
}

export function filterStaff(openShift, staff, expertises, expertiseRequirements, filter, dateTime, timeService) {
  const search = filter.get('search')
  const shiftTypeIds = filter.get('shiftTypes')
  const employmentTypes = filter.get('employmentTypes')
  const unitIds = filter.get('units')
  const expertiseIds = filter.get('expertises')
  const filteredExpertiseRequirements = expertiseRequirements.filter((item) =>
    expertiseIds.includes(item.get('expertiseId'))
  )
  const staffRequirementsService = new OpenShiftStaffRequirementsService(timeService)
  const shiftRoleIds = filter.get('shiftRoles')
  // const bestMatch = filter.get('bestMatch');
  const overtime = filter.get('overtime')
  const bonusPay = filter.get('bonusPay')

  if (search) {
    const lowerSearch = search.toLowerCase()
    staff = staff.filter((staff) => {
      const firstName = staff.getIn(['profile', 'firstName'])
      const lastName = staff.getIn(['profile', 'lastName'])
      const fullName = `${firstName} ${lastName}`

      return fullName.toLowerCase().indexOf(lowerSearch) >= 0
    })
  }

  return staff.filter((staff) => {
    const employmentType = staff.getIn(['staffProfile', 'employmentType'])
    const isEmploymentTypeInFilter = employmentTypes.includes(employmentType)
    if (!isEmploymentTypeInFilter) {
      return false
    }
    const openShiftEntity = new OpenShiftEntity(openShift)
    const staffEntity = new OpenShiftStaff(openShiftEntity, staff)
    const { hoursThisWeek, staffPreferences, expertiseCredentials, maximumNumberOfHoursPerWeek } = staffEntity
    const { preferenceNames } = staffPreferences
    const eligibleUnit = staffEntity.getEligibleUnitForDate(dateTime, timeService)
    if (!eligibleUnit) {
      return false
    }

    const shiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')
    const isShiftTypeInFilter = shiftTypeIds.includes(shiftTypeId)
    const homeUnitId = eligibleUnit.get('homeUnitId')
    const homeUnitRoleId = eligibleUnit.get('homeUnitRoleId')
    const isUnitsInFilter = unitIds.includes(homeUnitId)
    const isShiftRolesInFilter = shiftRoleIds.includes(homeUnitRoleId)
    const isExpertisesInFilter = staffRequirementsService.isMatchRequirements(
      filteredExpertiseRequirements,
      expertiseCredentials,
      expertises
    )
    const filteredOverTimer = !overtime || maximumNumberOfHoursPerWeek >= hoursThisWeek
    const filteredBonusPay =
      !bonusPay || preferenceNames.includes(StaffPreferences.preferenceNamesMap.willingToWorkBonusPay)

    return (
      isShiftTypeInFilter &&
      isUnitsInFilter &&
      isShiftRolesInFilter &&
      isExpertisesInFilter &&
      filteredOverTimer &&
      filteredBonusPay
    )
  })
}
