import { Component } from 'react'
import { pick, isEqual } from 'lodash'
import StaffGroup from './StaffGroup'
import { List } from 'immutable'
import classNames from 'classnames'
import StaffFilter from './StaffFilter'
import ScheduledStaffGroup from './StaffGroup/ScheduledStaffGroup'
import CustomNumericInput from '@humanics/he-react-common/lib/components/Form/CustomNumericInput'
import { OpenShiftStaff } from '../../../../../entityWrappers'

export default class StaffList extends Component {
  constructor(props) {
    super(props)
    const { isGroupCollapsed = false, requiredStaff = 0 } = props

    this.state = {
      requiredStaff,
      updatingTimeout: 0,
      isGroupCollapsed,
      isHidden: true,
      staffGroups: List()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { requiredStaff: prevRequiredStaff } = prevProps
    const { requiredStaff, type } = this.props

    const { staffGroups: prevStaffGroups, isHidden } = prevState
    const staffGroups = this.getStaffGroups()

    const isStaffRequirementsChanged = requiredStaff !== prevRequiredStaff
    const isStaffGroupsChanged = !isEqual(prevStaffGroups.toJS(), staffGroups.toJS())
    const size = this.getStaffGroupsSize(staffGroups)
    const isScheduledStaff = type === 'approvedStaff'

    if (isStaffRequirementsChanged) {
      this.setState({ requiredStaff })
    }
    if (isStaffGroupsChanged) {
      this.setState({ staffGroups })
    }
    if (isHidden && (size > 0 || isScheduledStaff)) {
      this.setState({ isHidden: false })
    }
  }

  render() {
    let { openShiftController, type, requiredStaff } = this.props
    const { isGroupCollapsed, staffGroups, isHidden } = this.state

    if (isHidden) {
      return null
    }

    const { openShift, selectedUserIds } = openShiftController
    const { isPosted } = openShift

    const label = getTitle(type, selectedUserIds)
    const iconClass = getIconClass(type)
    const isEligibleStaff = type === 'eligibleStaff'
    const isNotInvitedStaff = type === 'notInvitedStaff'
    const isScheduledStaff = type === 'approvedStaff'
    const size = this.getStaffGroupsSize(staffGroups)
    const selectedStaffSize = this.getStaffGroupsSize([selectedUserIds])

    const staffGroupCommonProps = pick({ ...this.props, isPosted }, [
      'roles',
      'eligibleUnits',
      'timeService',
      'isPosted',
      'openShiftController',
      'expertises'
    ])

    return (
      <div className="hx-open-shift-request-details-container" key={type} data-section-key={type}>
        <div className="row align-middle pb10">
          <h3 className="trout semibold cursor-pointer row align-middle" onClick={this.toggleGroup}>
            {!isScheduledStaff && (
              <>
                <span>{selectedStaffSize > 0 ? selectedStaffSize : size}</span>&nbsp;
              </>
            )}
            <span>{label}</span>&nbsp;
            <i
              className={classNames({
                [iconClass]: !isGroupCollapsed,
                'icon-right nano regent-gray': isGroupCollapsed
              })}
            />
          </h3>
          {isScheduledStaff && !isGroupCollapsed && this.renderTargetCover(size)}
        </div>
        <div
          className={classNames({
            'hx-open-shift-staff': !isScheduledStaff,
            hide: isGroupCollapsed
          })}
        >
          {(isEligibleStaff || isNotInvitedStaff) && <StaffFilter {...this.props} />}
          {isScheduledStaff &&
            staffGroups.map((staffGroup, index) => (
              <ScheduledStaffGroup
                {...staffGroupCommonProps}
                staff={staffGroup}
                type={type}
                key={`${type}-${index}`}
                requiredStaff={requiredStaff}
              />
            ))}
          {!isScheduledStaff && (
            <div className="hx-open-shift-staff-table bg-white">
              <Header type={type} />
              {staffGroups.map((staffGroup, index) => (
                <StaffGroup {...staffGroupCommonProps} staff={staffGroup} type={type} key={`${type}-${index}`} />
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }

  getStaffGroups = () => {
    let { openShiftController, openShiftStaffMap, type, expertises, timeService } = this.props
    const { openShift } = openShiftController
    const expertiseItems = expertises.get('expertiseItems')
    const { unitId } = openShift
    let staff = (openShiftStaffMap.get(type) || List())
      .map((staff) => {
        const openShiftStaff = new OpenShiftStaff(openShift, staff)
        const { staffExpertises, matchPercentage } = openShiftStaff.expertiseRequirements(expertiseItems, timeService)
        return staff.merge({
          staffExpertises,
          matchPercentage
        })
      })
      .sortBy((staff) => List([-staff.get('matchPercentage', 0), staff.get('fullName')]))

    const isEligibleStaff = type === 'eligibleStaff'
    const isNotInvitedStaff = type === 'notInvitedStaff'
    const isNeedsApprovalStaff = type === 'acceptedStaff'

    if (isNeedsApprovalStaff) {
      const approvedStaffUserIds = openShiftStaffMap.get('approvedStaff').map((facilityUser) => facilityUser.get('id'))
      staff = staff.filter((facilityUser) => !approvedStaffUserIds.includes(facilityUser.get('id')))
    }

    let staffGroups = List([])

    if (isEligibleStaff || isNotInvitedStaff) {
      const staffGroupsMap = staff.groupBy((staff) => staff.get('homeUnitId'))

      staffGroups = staffGroups
        .push(staffGroupsMap.get(unitId))
        .concat(staffGroupsMap.remove(unitId).valueSeq())
        .filter((group) => !!group)
    } else {
      staffGroups = staffGroups.push(staff)
    }

    return staffGroups
  }

  getStaffGroupsSize = (staffGroups) => staffGroups.reduce((size, staffGroup) => (size += staffGroup.size), 0)

  toggleGroup = () => this.setState({ isGroupCollapsed: !this.state.isGroupCollapsed })

  renderTargetCover = (size) => {
    const { openShift, isLoading } = this.props
    const { requiredStaff } = this.state

    if (!openShift) {
      return null
    }

    const inputProps = {
      type: 'math',
      order: '-i+',
      definition: {
        min: 0,
        disabled: isLoading
      },
      value: requiredStaff,
      onChange: this.onChangeTargetCover,
      formItemClasses: ''
    }
    return (
      <>
        <span
          className={classNames({
            _size: true,
            understaffed: size < requiredStaff,
            overstaffed: size > requiredStaff
          })}
        >
          {size}
        </span>
        <span className="row align-middle _targetCover">
          <label>
            <span className="form-label">Target Cover</span>
          </label>
          <CustomNumericInput {...inputProps} />
        </span>
      </>
    )
  }

  onChangeTargetCover = (requiredStaff) => {
    const { openShiftController, isLoading } = this.props
    const { requiredStaff: initRequiredStaff, updatingTimeout } = this.state
    if (isLoading) {
      return
    }
    if (updatingTimeout) {
      clearTimeout(this.state.updatingTimeout)
    }
    this.setState({ requiredStaff })
    if (initRequiredStaff !== requiredStaff) {
      this.setState({
        updatingTimeout: setTimeout(async () => {
          this.setState({ isLoading: true })
          await openShiftController.createShiftDayRequirement(requiredStaff)
          this.setState({ isLoading: false, initRequiredStaff: requiredStaff })
        }, 500)
      })
    }
  }
}

function Header({ type }) {
  const isEligibleStaff = type === 'eligibleStaff'

  return (
    <header className="row">
      <div />
      <div className="upper black gulf-blue">{isEligibleStaff ? 'eligible staff' : 'staff'}</div>
      <div className="upper black gulf-blue">matching</div>
      <div className="upper black gulf-blue">unit / position </div>
    </header>
  )
}

const getTitle = (type, selectedUserIds) => {
  switch (type) {
    case 'approvedStaff':
      return 'Scheduled Staff'
    case 'acceptedStaff':
      return 'Needs Approval'
    case 'notRespondedStaff':
      return 'Invited To Shift'
    case 'notInvitedStaff':
      return 'Invite Additional Staff'
    case 'eligibleStaff':
      return selectedUserIds.size === 0 ? 'Eligible Staff' : 'Selected Staff'
    default:
      return ''
  }
}

const getIconClass = (type) => {
  switch (type) {
    case 'acceptedStaff':
      return 'icon-has-access'
    case 'notRespondedStaff':
      return 'icon-pending-access'
    case 'notInvitedStaff':
      return 'icon-no-access'
    default:
      return ''
  }
}
