import moment from 'moment'
import momentWithTimeZone from 'moment-timezone'
import { findTimeZone, getUnixTime, getZonedTime } from 'timezone-support'

window.findTimeZone = findTimeZone
window.getZonedTime = getZonedTime
window.getUnixTime = getUnixTime
window.moment = moment
window.momentWithTimeZone = momentWithTimeZone

const buildArgsKey = (args) => {
  let key = ''
  args.forEach((arg) => {
    if (moment.isMoment(arg)) {
      key += Number(arg)
    } else {
      key += arg
    }
  })

  return key
}

function memorize(fn, options = {}) {
  const memo = {}
  return (...args) => {
    const key = buildArgsKey(args)
    if (memo.hasOwnProperty(key)) {
      const { except = [] } = options
      if (!except.includes(key)) {
        return memo[key]
      }
    }

    const value = fn.call(this, ...args)
    memo[key] = value
    return memo[key]
  }
}

/**
 * Custom wrapper over the `moment` lib with cache and timeZones support
 * @param {string} timeZone - {@link https://en.wikipedia.org/wiki/List_of_tz_database_time_zones canonical timeZones list}
 */
export default class TimeService {
  /**
   *
   * @param {string} timeZone - {@link https://en.wikipedia.org/wiki/List_of_tz_database_time_zones canonical timeZones list}
   */
  constructor(timeZone) {
    this.timeZone = findTimeZone(timeZone)
    this.timeMomentUnsafe = memorize.call(this, this.timeMomentUnsafe, {
      except: [buildArgsKey([null])]
    })
    this.dateTimeToMoment = memorize.call(this, this.dateTimeToMoment)
    this.isSameOrAfter = memorize.call(this, this.isSameOrAfter)
    this.isSameOrBefore = memorize.call(this, this.isSameOrBefore)
    this.addUnsafe = memorize.call(this, this.addUnsafe)
    this.isBetween = memorize.call(this, this.isBetween)
    this.isBefore = memorize.call(this, this.isBefore)
    this.isSame = memorize.call(this, this.isSame)
    this.isPrevDay = memorize.call(this, this.isPrevDay)
    this.isYesterday = memorize.call(this, this.isYesterday)
    this.isWeekend = memorize.call(this, this.isWeekend)
  }

  getOffsetInMinutes = (date) => {
    if (date === undefined) {
      throw new Error('date parameter is undefined')
    }
    if (!(date instanceof Date)) {
      date = date ? new Date(date) : new Date()
    }
    const timeZoneDateObject = getZonedTime(date, this.timeZone)
    return timeZoneDateObject.zone.offset
  }

  /**
   * Start of today with initialized time zone
   * @returns {moment.Moment}
   */
  get today() {
    return this._timeMoment(null).startOf('day')
  }

  /**
   * Start of yesterday with initialized time zone
   * @returns {moment.Moment}
   */
  get yesterday() {
    return this.today.clone().add(-1, 'day')
  }

  /**
   * Returns formatted today's string
   * @returns {string} MM-DD-YYYY
   */
  get todayUsDate() {
    return this.today.format('MM-DD-YYYY')
  }

  /**
   * Returns moment object from US date string with initialized TZ
   * @param {string} date in format MM-DD-YYYY
   * @returns {moment.Moment}
   */
  usDateToMoment(date) {
    const [month, day, year] = date.split('-')
    const time = { year, month, day, hours: 0, minutes: 0 }
    const nativeDate = new Date(getUnixTime(time, this.timeZone))
    return this.timeMoment(nativeDate)
  }

  /**
   * Wrapper for {@link moment.add} method
   * **Unsafe: Not cloned**
   * @param {moment.Moment} momentObj
   * @param {number} length
   * @param {string} units - minutes/hours/etc
   * @returns {moment.Moment}
   */
  addUnsafe(momentObj, length, units) {
    return momentObj.add(length, units)
  }

  /**
   * Wrapper for {@link moment.add} method
   * @param {moment.Moment} momentObj
   * @param rest - see {@link addUnsafe} params
   * @returns {moment.Moment}
   */
  add(momentObj, ...rest) {
    return this.addUnsafe(momentObj.clone(), ...rest)
  }

  /**
   * Wrapper for {@link moment} method
   * Cached output. For a real *now* moment {@link trueNow} should be used instead
   * @param args - see {@link _timeMoment} params
   * @returns {moment.Moment}
   */
  timeMoment(...args) {
    return this.timeMomentUnsafe(...args).clone()
  }

  /**
   * Wrapper for {@link moment} method
   * **Unsafe: Not cloned**
   * @param args - see {@link _timeMoment} params
   * @returns {moment.Moment}
   */
  timeMomentUnsafe(...args) {
    return this._timeMoment(...args)
  }

  /**
   * Wrapper for {@link moment} (without parameters only)
   * @returns {moment.Moment}
   */
  trueNow() {
    return this._timeMoment(null)
  }

  /**
   *
   * @param dateTime
   * @param format
   * @returns {moment.Moment}
   * @private
   */
  // TODO fix this
  _timeMoment(dateTime, format) {
    if (dateTime === undefined) {
      throw Error('dateTime parameter is undefined')
    }

    // Probably it's wrong approach to handle this case by this way in context of this method
    if (dateTime && format === 'HH:mm') {
      return moment(dateTime, format)
    }

    if (dateTime && format === 'hh:mm A') {
      return moment(dateTime, format)
    }

    // Probably it's wrong approach to handle this case by this way in context of this method
    const { name: timeZoneName } = this.timeZone

    if (dateTime && format === 'YYYY-w') {
      return momentWithTimeZone.tz(dateTime, format, timeZoneName)
    }

    if (!dateTime) {
      return momentWithTimeZone.tz(timeZoneName)
    }
    return momentWithTimeZone.tz(dateTime, timeZoneName)
  }

  /**
   * Wrapper for {@link moment.isSameOrAfter} method
   * @param {moment.Moment} momentObj
   * @param {string} dateISO
   */
  isSameOrAfter(momentObj, dateISO) {
    return momentObj.isSameOrAfter(dateISO)
  }

  /**
   * Wrapper for {@link moment.isSameOrBefore} method
   * @param {moment.Moment} momentObj
   * @param {string} dateISO
   */
  isSameOrBefore(momentObj, dateISO) {
    return momentObj.isSameOrBefore(dateISO)
  }

  /**
   * Wrapper for {@link moment.isBefore} method
   * @param {moment.Moment} momentObj
   * @param {string|moment.Moment|number|Date} dateISO
   */
  isBefore(momentObj, dateISO) {
    return momentObj.isBefore(dateISO)
  }

  /**
   * Wrapper for {@link moment.isSame} method
   * @param {moment.Moment} momentObj
   * @param {string|moment.Moment|number|Date} dateISO
   * @param {string} granularity year/day/month/etc
   */
  isSame(momentObj, dateISO, granularity) {
    return momentObj.isSame(dateISO, granularity)
  }

  /**
   *
   * @param {moment.Moment} momentObj
   * @param {string|moment.Moment|number|Date} startDateISO
   * @param {string|moment.Moment|number|Date} endDateISO
   * @param {string} granularity year/day/month/etc
   * @param {string} inclusivity `()`/`[)`/ etc
   */
  isBetween(momentObj, startDateISO, endDateISO, granularity, inclusivity) {
    return momentObj.isBetween(startDateISO, endDateISO, granularity, inclusivity)
  }

  /**
   * Converts dateISO with TZ to moment?
   * @param {string} dateISO
   * @returns {moment.Moment}
   */
  // TODO adjustTimeZone ?
  // apply fix for all dates globally for class?
  dateTimeToMoment(dateISO) {
    const utcOffset = this.getOffsetInMinutes(dateISO) / 60
    return moment(dateISO).utcOffset(-utcOffset, true)
  }

  /**
   *
   * @param {moment.Moment|string} date
   * @returns {boolean|undefined}
   */
  isWeekend(date) {
    if (!date) {
      return undefined
    }
    const dayMoment = this.timeMomentUnsafe(date)
    const dayIndex = dayMoment.day()
    return dayIndex === 6 || dayIndex === 0
  }

  /**
   *
   * @param {moment.Moment|string} date
   * @returns {boolean|undefined}
   */
  isToday(date) {
    if (!date) {
      return undefined
    }
    const dayMoment = this.timeMomentUnsafe(date)
    return this.isSame(this.today, dayMoment, 'day')
  }

  /**
   *
   * @param {moment.Moment|string} date
   * @returns {boolean|undefined}
   */
  isPrevDay(date) {
    if (!date) {
      return undefined
    }
    const dayMoment = this.timeMomentUnsafe(date)
    return this.isBefore(dayMoment, this.today) && !this.isToday(date)
  }

  /**
   *
   * @param {moment.Moment|string} date
   * @returns {boolean|undefined}
   */
  isYesterday(date) {
    if (!date) {
      return undefined
    }
    const dayMoment = this.timeMomentUnsafe(date)
    return this.isSame(this.yesterday, dayMoment, 'day')
  }

  /**
   * Calculate duration taking into account daylight saving time
   * @param {moment.Moment} momentObj
   * @param {number} duration in minutes
   * @returns {number} duration in minutes, corrected with DST
   */
  calculateDLSDuration = (momentObj, duration = 0) => {
    const startDate = momentObj.toDate()
    const endDate = new Date(startDate)

    endDate.setTime(endDate.getTime() + duration * 60 * 1000)
    const dlsChanging = this.calculateDLSChanging(startDate, endDate)

    return duration - dlsChanging
  }

  /**
   * Calculate dls offset between dates
   * @param {string|Date} startDate
   * @param {string|Date} endDate
   * @returns {number} minutes delta
   */
  calculateDLSChanging = (startDate, endDate) => {
    const startDateOffset = this.getOffsetInMinutes(startDate)
    const endDateOffset = this.getOffsetInMinutes(endDate)
    return startDateOffset - endDateOffset
  }

  /**
   * Converts 12-hours format string to 24-hours
   * Negative value allowed
   * @param {string} timeStr with format -12:34pm/12:34am
   * @param {boolean} full - if false, then :00 minutes will be stripped from the result
   * @returns {string} timeStr with format -12:34/00:34
   */
  amTo24 = (timeStr, full = true) => {
    timeStr = timeStr.toLowerCase()
    const zeros = ['00', '0', '']
    let addH = 0
    if (timeStr.includes('am')) {
      timeStr = timeStr.replace('am', '')
    } else if (timeStr.includes('pm')) {
      timeStr = timeStr.replace('pm', '')
      addH = 12
    } else {
      throw new Error(`Wrong input format: ${timeStr}`)
    }
    let [h, m = '00'] = timeStr.split(':')
    const isNegative = h.startsWith('-')
    if (isNegative) {
      h = h.substr(1)
    }
    if (h === '12') {
      h = '00'
    }
    h = `${+h + addH}`
    h = zeros[h.length] + h

    timeStr = `${isNegative ? '-' : ''}${h}:${m}`
    if (!full) {
      timeStr = timeStr.replace(':00', '')
    }

    return timeStr
  }

  /**
   * Converts 12:34 str to minutes
   * Negative value allowed
   * @param {string} timeStr with format -13:34/13:34
   * @returns {number} minutes
   */
  timeToMinutes = (timeStr) => {
    const factor = timeStr.startsWith('-') ? -1 : 1

    let [hours, minutes] = timeStr.split(':')

    return (Math.abs(+hours) * 60 + Number(minutes)) * factor
  }

  /**
   * Applies time shift to start of provided day
   * Negative value allowed
   * @param {string} timeStr with format -13:34/13:34
   * @param {moment.Moment|Date|string} date
   * @returns {moment.Moment}
   */
  applyTimeToDate = (timeStr, date) => {
    const offset = this.timeToMinutes(timeStr)

    const startOfDay = this.timeMoment(date).startOf('day')
    const trueStartsAtOffset = this.calculateDLSDuration(startOfDay, offset)

    return startOfDay.clone().add(trueStartsAtOffset, 'minute')
  }

  /**
   * Represent passed time range (startsAt - endsAt) in specific format
   * @param {startsAt} timeStr with ISO format
   * @param {endsAt} timeStr with ISO format
   * @param {format} string to represent result format, default is 'HH:mm'
   * @returns {string}
   */
  formatTimeRange = (startsAt, endsAt, format = 'HH:mm') => {
    const startTimeMoment = this.timeMoment(startsAt)
    const endTimeMoment = this.timeMoment(endsAt)
    const start = startTimeMoment.format(format)
    const end = endTimeMoment.format(format)

    return `${start} - ${end}`
  }

  /**
   *
   * @param {string} date date in string format
   * @param {string} inputFormat to represnt input string format
   * @param {string} outputFormat to represnt result format
   * @returns {moment} returns date in outputFormat
   */
  formatDate(date, inputFormat, outputFormat) {
    return moment(date, inputFormat).format(outputFormat)
  }

  /**
   *
   * @param {string} date in string format
   * @returns {string} returns month year string in 'MMMM YYYY' format
   */
  getMonthYearForUsDate(date) {
    return this.formatDate(date, 'MM-DD-YYYY', 'MMMM YYYY')
  }

  /**
   *
   * @param {string} date in string format
   * @returns {string} returns month year string in 'MMMM YYYY' format
   */
  getDayMonthYearForUsDate(date) {
    return this.formatDate(date, 'MM-DD-YYYY', 'dddd, D MMMM YYYY')
  }

  /**
   *
   * @param {string} date in string format
   * @returns {string} returns date month string in 'D MMMM' format
   */
  getDateMonthForUsDate(date) {
    return this.formatDate(date, 'MM-DD-YYYY', 'D MMMM')
  }

  /**
   *
   * @param {string} date
   * @param {number} numberOfMonths
   * @param {string} outputFormat to represnt result format
   * @returns {moment} returns newMonthMoment
   */
  getMonth(date, numberOfMonths, outputFormat) {
    const currentDate = this.usDateToMoment(date).startOf('month')
    const newMonthMoment = currentDate.clone().add(numberOfMonths, 'month')
    return this.timeMoment(newMonthMoment).format(outputFormat)
  }

  /**
   *
   * @param {string} date
   * @returns {moment} returns date in ddd, D MMM YYYY format
   */
  getDayDateMonthYear(date) {
    return this.formatDate(date, '', 'ddd, D MMM YYYY')
  }
}
