import { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Admin, Header, Search } from '@humanics/he-react-common/lib/admin/components'
import ModalWithConfirmation from '@humanics/he-react-common/lib/admin/components/ModalWithConfirmation'
import EquipmentsController from './EquipmentsController'
import { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import imagePlaceholder from '../../styles/web_v3/image_placeholder.png'

const fields = [
  Field('title', 'Name', { format: 'plain' }),
  Field('description', 'Description', { format: 'plain' }),
  Field('skillNames', 'Skills', { format: 'plain' })
]

class EquipmentsAdmin extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.controller = new EquipmentsController(this)
    const data = this.controller.getEquipments()
    this.state = { fields, data, query: '', apiInitialized: false }
  }

  componentDidMount() {
    ;(async () => {
      const { store } = this.props
      await store.initializeApi()
      await this.controller.expertiseSkillStore.getApi()
      this.controller.loadData()
      this.setState({ apiInitialized: true })
    })()
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { stateKey: stateGroupKey } = this.controller.expertiseSkillStore
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const storeGroupState = appState.get(stateGroupKey)
    const prevStoreState = prevAppState.get(stateKey)
    const prevStoreGroupState = prevAppState.get(stateGroupKey)
    const isStoreStateChanged = storeState !== prevStoreState || storeGroupState !== prevStoreGroupState

    const { query: prevQuery } = prevState
    const { query } = this.state
    const isQueryUpdated = prevQuery !== query

    if (isStoreStateChanged || isQueryUpdated) {
      const data = this.controller.getEquipments()
      this.setState({ data })
    }
  }

  render() {
    const { appState, store, stores, location, history, subRoutes, match } = this.props
    const { isConfirmationModalVisible, isGroupConfirmationModalVisible, data, apiInitialized, query } = this.state
    if (!apiInitialized) {
      return null
    }

    const isStoreApiInitialized = store.isApiInitialized(appState)
    if (!isStoreApiInitialized) {
      return null
    }

    const groupAction = {
      linkTo: location.pathname.replace(/\/$/, '') + '/new',
      actionTitle: 'Create Equipment'
    }
    const { groupedEquipments, emptyGroups } = data

    const groupItems = {
      groupBy: 'groupId',
      groupNameField: 'groupName',
      emptyGroups
    }

    const fields = [
      {
        render: (dataRow) => {
          const { imageUrl } = dataRow
          return (
            <td className="image-cell">
              <img className="image-48" src={imageUrl || imagePlaceholder} alt="" />
            </td>
          )
        }
      },
      ...this.state.fields,
      {
        render: (dataRow) => {
          const { id: equipmentId } = dataRow
          return (
            <td>
              <span className="float-right pr10">
                <i
                  className="icon icon-unfilled-trash cursor-pointer"
                  onClick={(e) => this.onDeleteEquipmentInitiated(e, equipmentId)}
                />
              </span>
            </td>
          )
        }
      }
    ]

    const confirmationModalProps = {
      modalTitle: 'Delete Equipment with Skills',
      confirmationMessage: 'Do you really want to delete this equipment with skills?',
      isCancelVisible: true,
      isConfirmVisible: true,
      onCancelConfirmation: () => this.setState({ isConfirmationModalVisible: false, deleteParameters: null }),
      onConfirm: this.onDeleteEquipment,
      isModalVisible: isConfirmationModalVisible,
      isConfirmationVisible: isConfirmationModalVisible
    }
    const groupConfirmationModalProps = {
      modalTitle: 'Delete Group',
      confirmationMessage: 'Do you really want to delete this group?',
      isCancelVisible: true,
      isConfirmVisible: true,
      onCancelConfirmation: () =>
        this.setState({ isGroupConfirmationModalVisible: false, deleteGroupParameters: null }),
      onConfirm: this.onDeleteEquipmentGroup,
      isModalVisible: isGroupConfirmationModalVisible,
      isConfirmationVisible: isGroupConfirmationModalVisible
    }
    const linkTo = location.pathname.replace(/\/$/, '') + '/new'
    const actions = (
      <Link to={linkTo} className="button secondary">
        Create Equipment Group
      </Link>
    )

    const { search, stateKey } = store.getAdminInfo()
    return (
      <>
        <ModalWithConfirmation {...confirmationModalProps} />
        <ModalWithConfirmation {...groupConfirmationModalProps} />
        <Admin>
          <Header title="Equipments" actions={actions} />
          <Grid
            orderField="position"
            history={history}
            Link={Link}
            renderDataRow={this.renderDataRow}
            updateItemPosition={this.updateItemPosition}
            data={groupedEquipments}
            store={store}
            dataType={stateKey}
            fields={fields}
            allowUpdate={true}
            groupItems={groupItems}
            stores={stores}
            groupAction={groupAction}
            groupDelete={this.onDeleteEquipmentGroupInitiated}
            currentPath={match.url}
          >
            {!search ? null : (
              <Search
                keyword={query}
                onReset={(e) => e?.preventDefault()}
                placeholder={search?.placeholder}
                onSearch={(query) => this.setState({ query })}
                itemsFound={groupedEquipments}
                entityName="Equipments"
              />
            )}
          </Grid>
          {subRoutes}
        </Admin>
      </>
    )
  }

  onDeleteEquipmentInitiated = (event, equipmentId) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ isConfirmationModalVisible: true, deleteParameters: { equipmentId } })
  }

  onDeleteEquipment = async () => {
    const { equipmentId } = this.state.deleteParameters
    try {
      await this.controller.deleteEquipment(equipmentId)
    } catch (e) {
      //
    }
    this.controller.loadData()
    this.setState({ isConfirmationModalVisible: false, deleteParameters: null })
  }

  onDeleteEquipmentGroupInitiated = (event, groupId) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ isGroupConfirmationModalVisible: true, deleteParameters: { groupId } })
  }

  onDeleteEquipmentGroup = async () => {
    const { groupId } = this.state.deleteParameters
    try {
      await this.controller.deleteEquipment(groupId)
    } catch (e) {
      //
    }
    this.controller.loadData()
    this.setState({ isGroupConfirmationModalVisible: false, deleteParameters: null })
  }
}

export default EquipmentsAdmin
