// app routes
export const Root = '/'

export const Admin = '/admin'
export const Manager = Root
export const CommonManager = '/:urlId/:unitUrlId/:componentId/:date?'
export const StaffPage = '/staff'
// export const CommonManager = '/facility/:facilityId/:module/:unitId/:date';

// hashtag routes
export const PrivacyPolicy = '#privacy-policy'
export const TermsOfService = '#terms-of-service'
export const ReadmeOSS = '#readme-oss'

// public routes
export const Registration = '/registration/:activationToken'
export const MagicLink = '/createSessionByLoginToken/:loginToken'
export const NoPassword = '/forgot-password'
export const ChangePassword = '/changePassword/:changePasswordToken'
export const Mfa = '/mfa'
export const Login = '/login'
export const Logout = '/logout'

export const Forbidden = '/forbidden'
export const NoUnits = '/no-units'

export const WithAccessBar = [Admin, CommonManager, StaffPage]

// Facility Routes
export const FacilityHome = '/:urlId'
export const FacilityUnitHome = `${FacilityHome}/:unitUrlId`
export const FacilityUnitModuleHome = `${FacilityUnitHome}/:componentId`
export const FacilityUnitModuleDate = `${FacilityUnitModuleHome}/:date`
// const Modules = 'calendar|open-shifts|shift-swaps|time-off|staff|activities';
// const CalendarModules = 'calendar|open-shifts|shift-swaps';

// private routes
//TODO: deprace `Home`
export const Home = FacilityHome

// export const RootModule = `${Home}/:module(${Modules})`;
// export const CalendarRootModule = `${Home}/:module(${CalendarModules})`;
// export const Layout = `${CalendarRootModule}/:unitUrlId/:mode(full|week|day)?/:date`;
// export const CommonLayout = `${Home}/:module/:unitUrlId/:mode?/:date?`;

// Calendar
export const CalendarDefault = `${FacilityUnitHome}/calendar/:date`
export const Calendar = `${CalendarDefault}/:mode`
export const CalendarFullView = `${CalendarDefault}/full`
export const CalendarWeekView = `${CalendarDefault}/week`
export const CalendarDayView = `${CalendarDefault}/day`
export const PrintPDF = `${Calendar}/print`
export const ShiftDayRequirement = `${Calendar}/shift-day-requirement/:groupId/:shiftId/:dayIndex`

//TimeOff Requests
export const BaseTimeOff = `${FacilityUnitHome}/time-off`
export const TimeOff = `${BaseTimeOff}/:componentId`
export const TimeOffPendingRequests = `${BaseTimeOff}/pending-requests`
export const TimeOffYearView = `${BaseTimeOff}/year-view`

// Activities
export const Activities = `${FacilityUnitHome}/activities`

// Activities
export const PatientSlotRecommender = `${FacilityUnitHome}/patient-slot-recommender`

// Staff - Account Management
export const Staff = `${FacilityUnitHome}/staff`
export const StaffNew = `${Staff}/new`
export const AcceptStaffTransfer = `${Staff}/accept-transfer/:userId`
export const StaffDetails = `${Staff}/:date/:mode/:userId`
export const StaffEdit = `${StaffDetails}/edit`
export const StaffUnitAssignment = `${StaffDetails}/tranfer-unit-assignment`
export const StaffTransferDepartment = `${StaffDetails}/transfer-department`
export const StaffCreateRecurringSchedule = `${StaffDetails}/recurring/new`
// export const StaffEditRecurringSchedule = `${StaffDetails}/recurring/:staffComplianceRuleId`;
export const StaffEditRecurringSchedule = `${StaffDetails}/recurring/:staffRecurringScheduleId`
export const UnitStaffShiftAssignments = `${StaffDetails}/assignments/:assignmentUnitId`
export const StaffCreateSkill = `${StaffDetails}/skill/new`
export const StaffEditSkill = `${StaffDetails}/skill/:expertiseId`
export const StaffCreateCertification = `${StaffDetails}/certification/new`
export const StaffEditCertification = `${StaffDetails}/certification/:expertiseId`
export const StaffCreateEquipment = `${StaffDetails}/equipment/new`
export const StaffEditEquipment = `${StaffDetails}/equipment/:expertiseId`
export const StaffCreateLicense = `${StaffDetails}/license/new`
export const StaffEditLicense = `${StaffDetails}/license/:expertiseId`

// Shift Swaps
export const ShiftSwaps = `${FacilityUnitHome}/shift-swaps`

// Open Shifts
export const OpenShifts = `${FacilityUnitHome}/open-shifts`
export const OpenShift = `${OpenShifts}/:shiftDayId`
export const ShiftOpportunity = `${OpenShifts}/posted/:id`

// Announcements
export const Announcements = `${FacilityUnitHome}/announcements`
export const AnnouncementsCreate = `${Announcements}/create`
export const AnnouncementsDetails = `${Announcements}/:announcementId`
export const AnnouncementsEdit = `${AnnouncementsDetails}/edit`

// Engagement center
export const EngagementCenter = `${FacilityUnitHome}/engagement-center`

// Admin
export const AdminFacilities = `${Admin}/system/accounts/items`
export const AdminFacilityCreate = `${AdminFacilities}/new`
export const AdminIdns = `${Admin}/system/accounts/idns`
export const AdminIdnCreate = `${AdminIdns}/new`

export const FacilityAdmin = `${Admin}${FacilityHome}`

export const AdminUnits = `${FacilityAdmin}/units/items`
export const AdminShiftTypes = `${FacilityAdmin}/units/shift-types`
export const AdminRoles = `${FacilityAdmin}/units/roles`
export const AdminRolesUpdate = `${FacilityAdmin}/units/roles/:id`
export const AdminShifts = `${FacilityAdmin}/units/shifts`
export const AdminCensus = `${FacilityAdmin}/units/census`
export const AdminPositions = `${FacilityAdmin}/users/positions`
export const AdminRules = `${FacilityAdmin}/users/compliance`
export const AdminAvailabilities = `${FacilityAdmin}/users/availabilities`
export const AdminPreferences = `${FacilityAdmin}/users/preferences`
export const AdminStaff = `${FacilityAdmin}/users/items`
export const AdminStaffNew = `${AdminStaff}/new`
export const AdminStaffUpdate = `${AdminStaff}/:id`

//Licenses
export const AdminLicenses = `${FacilityAdmin}/licenses`
export const AdminLicensesNew = `${FacilityAdmin}/licenses/new`
export const AdminLicensesUpdate = `${FacilityAdmin}/licenses/:id`
//Credentials
export const AdminCertifications = `${FacilityAdmin}/certifications`
export const AdminCertificationsNew = `${FacilityAdmin}/certifications/new`
export const AdminCertificationsUpdate = `${FacilityAdmin}/certifications/:id`
//Skills
export const AdminSkills = `${FacilityAdmin}/skills`
export const AdminSkillsNew = `${FacilityAdmin}/skills/new`
export const AdminSkillsUpdate = `${FacilityAdmin}/skills/:id`
//Equipments
export const AdminEquipments = `${FacilityAdmin}/equipments`
export const AdminEquipmentsNew = `${FacilityAdmin}/equipments/new`
export const AdminEquipmentsUpdate = `${FacilityAdmin}/equipments/:id`

export const Dashboard = `${FacilityUnitHome}/dashboard`
export const ToDo = `${Dashboard}/todo`
export const LaborProfile = `${Dashboard}/laborprofile`
export const SkillsCheck = `${Dashboard}/skillscheck`

// Staff Routes
export const FacilityStaff = `${StaffPage}${FacilityHome}`

export const StaffProfilePage = `${FacilityStaff}/profile`
export const StaffProfileEdit = `${StaffProfilePage}/edit`
export const StaffCalendarPage = `${FacilityStaff}/calendar/:date/:section(shifts-assigned|shift-swaps|time-offs)?`
export const StaffCalendarPageNewItem = `${StaffCalendarPage}/new`
export const StaffNowPage = `${FacilityStaff}/now`
export const StaffCalendarPageNew = `${StaffCalendarPage}/new`

//SkillsCheck
export const SkillscheckAdministratorPage = `${FacilityHome}/skillscheck`
