import { createRef, PureComponent } from 'react'
import { Map } from 'immutable'
import classNames from 'classnames'
import { padStart, maxBy, minBy } from 'lodash'
import memoize from 'memoize-one'
import { SpillProofDiv } from 'Common/components'
import './StaffEvent.scss'
import { Avatar } from 'Manager/components'

export default class StaffEvent extends PureComponent {
  ref = createRef()

  static defaultProps = {
    cell: Map(),
    minHour: 0,
    maxHour: 23,
    step: 15,
    resizeEnabled: true,
    isShiftViewBar: false
  }

  constructor(props) {
    super(props)
    this.state = {
      resizeStarted: false,
      moveStarted: false,
      percent: 100,
      resizeOn: 1,
      curWidth: 1,
      offsetLeft: 0,
      startsAtHour: 0,
      endsAtHour: 23,
      hoverExpanded: false
    }
  }

  componentDidMount() {
    const { eventBar } = this.props
    if (eventBar) {
      const { startsAtHours, firstHour, endsAtHours, lastHour } = eventBar
      this.setState({
        startsAtHour: startsAtHours + (1 - firstHour),
        endsAtHour: endsAtHours + lastHour - 1
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { showPopup, cell, eventBar } = this.props
    const { cell: prevCell, eventBar: prevEventBar } = prevProps
    if (eventBar !== prevEventBar) {
      const { startsAtHours, firstHour, endsAtHours, lastHour } = eventBar
      this.setState({
        startsAtHour: startsAtHours + (1 - firstHour),
        endsAtHour: endsAtHours + lastHour - 1
      })
    }
    const isLastSelectedCell = cell?.get('isSelected')
    const prevIsLastSelectedCell = prevCell?.get('isSelected')
    const isCellSelectionChanged = isLastSelectedCell !== prevIsLastSelectedCell

    if (isCellSelectionChanged && isLastSelectedCell) {
      showPopup(this.ref.current)
    }
  }

  getFirstPosition() {
    const { minHour } = this.props
    const { startsAtHour } = this.state

    const oneCellWidth = 100 / 24
    let left = 0
    if (startsAtHour > minHour) {
      left = oneCellWidth * (startsAtHour - minHour) + '%'
    }
    return left
  }

  getWidth() {
    if (this.state.hoverExpanded) {
      return 'unset'
    }
    const { minHour, maxHour } = this.props
    const oneCellWidth = 100 / (maxHour - minHour + 1)
    const { startsAtHour, endsAtHour } = this.state
    return (endsAtHour - startsAtHour + 1) * oneCellWidth + '%'
  }

  updateEventStart = (startsAtHour) => {
    const { minHour } = this.props
    this.setState({
      startsAtHour: maxBy([startsAtHour, minHour])
    })
  }

  updateEventEnd = (endsAtHour) => {
    const { maxHour } = this.props
    this.setState({
      endsAtHour: minBy([endsAtHour, maxHour])
    })
  }

  resizeStart = (e) => {
    const { resizeEnabled } = this.props
    if (!resizeEnabled) {
      return
    }
    const mouseX = e.clientX || e.touches[0].pageX
    const curWidth = this.ref.current.clientWidth
    this.setState({
      resizeOn: mouseX,
      curWidth,
      resizeStarted: true
    })
  }

  moveStart = (e) => {
    const { resizeEnabled, eventBar } = this.props
    if (!resizeEnabled || eventBar.startsDayBefore) {
      return
    }
    const mouseX = e.clientX || e.touches[0].pageX
    const curWidth = this.ref.current.clientWidth
    this.setState({
      resizeOn: mouseX,
      curWidth,
      moveStarted: true
    })
  }

  resizeStop = () => {
    const { updateEvent, eventBar, timeService } = this.props
    const { startsAtHour, endsAtHour } = this.state
    const { startsAt, startsDayBefore, id: staffEventId } = eventBar
    this.setState({
      resizeStarted: false,
      moveStarted: false
    })
    let duration = (endsAtHour - startsAtHour + 1) * 60
    const hour = Math.floor(startsAtHour)
    const minutesFloat = startsAtHour - hour
    const minute = Math.floor(minutesFloat * 60)
    let startTime = timeService.timeMoment(startsAt)

    if (startsDayBefore) {
      const durationDifference = startTime.clone().add(1, 'days').startOf('day').diff(startTime, 'minutes')
      duration += durationDifference
    }

    if (!startsDayBefore) {
      startTime = startTime.set({ hour, minute })
    }

    const eventParameters = Map({ startsAt: startTime, duration, staffEventId })
    return updateEvent(eventParameters)
  }

  handleMouseMove = (e) => {
    const { resizeEnabled, eventBar, step } = this.props
    if (!resizeEnabled) {
      return
    }
    const { resizeStarted, moveStarted, resizeOn, curWidth } = this.state
    const { startsAtHours, firstHour, endsAtHours, lastHour } = eventBar
    const startsAtHour = startsAtHours + (1 - firstHour)
    const endsAtHour = endsAtHours + lastHour
    if (resizeStarted) {
      const mouseX = e.clientX || e.touches[0].pageX
      const newPct = (curWidth + mouseX - resizeOn) / curWidth
      const currentMinutes = (endsAtHour - startsAtHour - 1) * 60
      const newMinutes = Math.round(currentMinutes * newPct)
      if (newMinutes % step === 0 && newMinutes >= step) {
        const newHours = startsAtHour + newMinutes / 60
        this.updateEventEnd(newHours)
      }
    } else if (moveStarted) {
      const mouseX = e.clientX || e.touches[0].pageX
      const newPct = (mouseX - resizeOn) / curWidth
      const currentMinutes = (endsAtHour - startsAtHour - 1) * 60
      const newMinutes = Math.round(currentMinutes * newPct)
      if (newMinutes % step === 0 && newMinutes <= currentMinutes - step) {
        const newHours = startsAtHour + newMinutes / 60
        this.updateEventStart(newHours)
      }
    }
  }

  _getCurrentStaffEvent = memoize((staffEvents) => {
    const { eventBar } = this.props
    return staffEvents?.find((staffEvent) => staffEvent.get('id') === eventBar.id) || Map()
  })

  _checkEventBarCanBeShown = (cellStaffEvent, dayViewFilters) => {
    const isPTO = cellStaffEvent.get('isPTO')
    const isUnavailable = cellStaffEvent.get('isUnavailable')
    const isMeeting = cellStaffEvent.get('isMeeting')
    const isDayOff = this.isDayOff(isPTO, isUnavailable)

    return !(
      (!dayViewFilters.get('timeOff') && isDayOff && isPTO) ||
      (!dayViewFilters.get('requestOff') && isDayOff && !isPTO) ||
      (!dayViewFilters.get('pto') && isDayOff && isPTO) ||
      (!dayViewFilters.get('classOrientation') && isMeeting)
    )
  }

  render() {
    const { resizeEnabled, eventBar, minHour, dayViewFilters, cell, dayIndex } = this.props
    if (!eventBar) {
      return null
    }
    const cellStaffEvent = this._getCurrentStaffEvent(cell.get('staffEvents'))
    if (!this._checkEventBarCanBeShown(cellStaffEvent, dayViewFilters)) {
      return null
    }

    const { resizeStarted, moveStarted } = this.state
    const content = this.renderContent()
    const identityHash = eventBar.id || 'empty'
    const halfBarWidth = this.ref.current?.offsetWidth && 0.5 * this.ref.current?.offsetWidth
    return (
      <>
        <div
          data-is-event-bar-element={true}
          data-day-cell-index={dayIndex}
          data-cell-identity-hash={identityHash}
          className={classNames({ 'hx-box-shadow-event-bar': this.state.hoverExpanded })}
          tabIndex={0}
          ref={this.ref}
          style={this.getStyles()}
          key={eventBar.id}
        >
          <div
            style={{
              height: '100%',
              width: halfBarWidth ? `${Math.min(20, halfBarWidth)}px` : '20px',
              position: 'absolute',
              top: '0',
              left: '-1px',
              cursor: resizeEnabled ? 'col-resize' : 'auto'
            }}
            onMouseDown={this.moveStart}
          />
          {content}
          <div
            style={{
              height: '100%',
              width: halfBarWidth ? `${Math.min(20, halfBarWidth)}px` : '20px',
              position: 'absolute',
              top: '0',
              right: '0',
              cursor: resizeEnabled ? 'col-resize' : 'auto'
            }}
            onMouseDown={this.resizeStart}
          />
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            cursor: 'col-resize',
            position: 'absolute',
            top: minHour > 0 ? '-100%' : 0,
            display: resizeStarted || moveStarted ? 'inline-block' : 'none'
          }}
          onMouseUp={this.resizeStop}
          onMouseMove={this.handleMouseMove}
        />
      </>
    )
  }

  getStyles = () => {
    const { eventBar, isShiftViewBar } = this.props
    const { type: eventType } = eventBar
    const isTimeOff = eventType === 'timeOff'
    const { resizeStarted, moveStarted } = this.state
    const width = this.getWidth()
    const left = this.getFirstPosition()
    const borderRadius = isShiftViewBar ? 999 : 5
    const top = isShiftViewBar ? '10%' : '20%'
    let floatingStyles = {}
    if (this.state.hoverExpanded) {
      floatingStyles = {
        zIndex: 1
      }
    }
    if (resizeStarted || moveStarted) {
      if (isTimeOff) {
        return {
          height: '100%',
          position: 'absolute',
          backgroundColor: '#F7F8FA',
          left,
          width,
          display: 'flex',
          flexDirection: 'row',
          ...floatingStyles,
          borderRadius: 5,
          borderLeft: 'solid 3px',
          borderRight: 'solid 3px',
          borderTop: 'solid 1px',
          borderBottom: 'solid 1px',
          borderColor: '#459AFE'
        }
      }
      return {
        position: 'absolute',
        width,
        left,
        top: isShiftViewBar ? '0' : '20%',
        borderRadius,
        backgroundColor: '#fff',
        borderLeft: 'solid 3px',
        borderRight: 'solid 3px',
        borderTop: 'solid 1px',
        borderBottom: 'solid 1px',
        borderColor: '#459AFE',
        ...floatingStyles
      }
    }
    const extendedPreviewContentStyle = {
      position: 'absolute',
      backgroundColor: '#9EB1C8',
      width,
      left,
      borderRadius,
      top,
      ...floatingStyles
    }
    let extendedTimeOffContentStyle = {
      height: '100%',
      position: 'absolute',
      backgroundColor: '#F7F8FA',
      left,
      width,
      ...floatingStyles
    }

    if (eventBar?.timeOffAttributes?.isPartial) {
      extendedTimeOffContentStyle = {
        ...extendedTimeOffContentStyle,
        backgroundImage:
          'linear-gradient(135deg, #dedfe1 25%, #f7f8fa 25%, #f7f8fa 50%, #dedfe1 50%, #dedfe1 75%, #f7f8fa 75%, #f7f8fa 100%)',
        backgroundSize: '6.00px 6.00px',
        lineHeight: '2em',
        verticalAlign: 'middle',
        zIndex: 2
      }
    }

    return isTimeOff ? extendedTimeOffContentStyle : extendedPreviewContentStyle
  }

  getUpdatedBy = (cell) => {
    const { staffManagersMap } = this.props
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const updatedById = cellStaffEvent.get('updatedBy')
    const manager = staffManagersMap.get(updatedById) || Map()
    const profile = manager.get('profile') || Map()
    const firstName = profile.get('firstName') || ''
    const lastName = profile.get('lastName') || ''
    return `${firstName} ${lastName}`.trim()
  }

  getStartsAtFormatted = (startsDayBefore, startsAt, startsAtHour) => {
    const { timeService } = this.props
    return startsDayBefore && startsAt
      ? timeService.timeMoment(startsAt).format('HH:mm')
      : convertHoursToTime(startsAtHour)
  }

  isShiftEvent = (cellStaffEvent) => {
    const isAssignment = cellStaffEvent.get('isAssignment')
    const isMeeting = cellStaffEvent.get('isMeeting')
    const isOnCall = cellStaffEvent.get('isOnCall')
    return isAssignment || isMeeting || isOnCall
  }

  isDayOff = (isPTO, isUnavailable) => {
    return isPTO || isUnavailable
  }

  getNote = (notesCollection) => {
    return notesCollection.last() || Map()
  }

  getSubject = (note) => {
    return note.get('subject') || ''
  }

  getUpdatedOn = (note) => {
    return note.get('createdAt') || note.get('updatedAt')
  }

  getLastUpdateTime = (updatedOn) => {
    const { timeService } = this.props
    return (updatedOn && timeService.timeMoment(updatedOn).format('MM-D-YY, HH:mm')) || ''
  }

  getShiftViewBarContent = () => {
    const { facilityUsersMap, eventBar } = this.props
    const { startsAtHour, endsAtHour } = this.state
    const { startsDayBefore, startsAt } = eventBar
    const startsAtFormatted = this.getStartsAtFormatted(startsDayBefore, startsAt, startsAtHour)
    const profile = facilityUsersMap.get(eventBar.userId)?.get('profile')
    if (!profile) {
      return null
    }

    return (
      <span className="mr10">
        <div className="row-nowrap align-middle p4">
          <Avatar profile={profile} />
          <div className="row-nowrap">
            <div className="mr10">
              {profile.get('firstName')} {profile.get('lastName')}
            </div>
            <div>
              {startsAtFormatted} - {convertHoursToTime(endsAtHour + 1)}
            </div>
          </div>
        </div>
      </span>
    )
  }

  getDayOffContent = () => {
    const { cell, notes, dayIndex, eventBar } = this.props
    const { startsAtHour, endsAtHour } = this.state
    const { startsDayBefore, startsAt } = eventBar
    const currentStaffEvent = this._getCurrentStaffEvent(cell.get('staffEvents'))
    const noteIds = currentStaffEvent.get('noteIds')
    const notesCollection = noteIds.map((noteId) => notes.get(noteId)).filter((note) => note)
    const note = this.getNote(notesCollection)
    const subject = this.getSubject(note)
    const updatedOn = this.getUpdatedOn(note)
    const lastUpdateTime = this.getLastUpdateTime(updatedOn)
    const name = this.getUpdatedBy(cell)
    const isPTO = currentStaffEvent.get('isPTO')
    const isPartialTimeOff = currentStaffEvent.get('isPartialShiftEvent')

    const icon = this.getTypeIcon()
    const setIconDetailed = this.getSetIcons(cell)
    const lastUpdateAuthor = name ? `by ${name}` : ''
    const lastUpdateInformation = `Updated ${lastUpdateTime} ${lastUpdateAuthor}`
    const identityHash = eventBar.id || 'empty'
    const startsAtFormatted = this.getStartsAtFormatted(startsDayBefore, startsAt, startsAtHour)

    return (
      <span className="cursor-default">
        <div data-is-event-bar-element={true} data-day-cell-index={dayIndex} data-cell-identity-hash={identityHash}>
          <div className="stronger disabled-pointer">
            <span className="fullview-cell-icons">
              <i style={{ marginLeft: 10 }} className={icon} />
            </span>
            {isPTO ? 'PTO' : 'Requested Day Off'} {subject ? `(${subject})` : ''}{' '}
            {isPartialTimeOff ? `- ${startsAtFormatted} - ${convertHoursToTime(endsAtHour + 1)}` : ''}
            <span className="fullview-cell-icons">
              {setIconDetailed.map((icon) => (
                <i key={`${icon}-key-icon`} className={icon + ' ml5'} />
              ))}
            </span>
          </div>
          <div className="regent-gray cite disabled-pointer">{lastUpdateInformation}</div>
        </div>
      </span>
    )
  }

  getStaffViewBarContent = () => {
    const { cell, dayIndex, eventBar } = this.props
    const { startsAtHour, endsAtHour } = this.state
    const { startsDayBefore, startsAt } = eventBar
    const icon = this.getTypeIcon()
    const setIconDetailed = this.getSetIcons()
    const identityHash = eventBar.id || 'empty'

    const startsAtFormatted = this.getStartsAtFormatted(startsDayBefore, startsAt, startsAtHour)
    return (
      <div
        data-is-event-bar-element={true}
        data-day-cell-index={dayIndex}
        data-cell-identity-hash={identityHash}
        className="cursor-default content"
      >
        <span className="fullview-cell-icons">
          <i className={icon} />
        </span>
        <span className="ml10 mr10 disabled-pointer">
          <div className="stronger row-nowrap align-middle p4">
            {startsAtFormatted} - {convertHoursToTime(endsAtHour + 1)}
          </div>
        </span>
        <span className="fullview-cell-icons mr10 disabled-pointer">
          {setIconDetailed.map((icon) => {
            let additionalStyle = {}
            if (icon === 'icon-indicator-info') {
              const currentStaffEvent = this._getCurrentStaffEvent(cell.get('staffEvents'))
              const isPartialShiftEvent = currentStaffEvent.get('isPartialShiftEvent')
              additionalStyle = isPartialShiftEvent && {
                backgroundColor: 'red',
                borderRadius: '50%'
              }
            }
            return <i key={`${icon}-key-icon`} className={icon} style={{ marginLeft: 5, ...additionalStyle }} />
          })}
        </span>
      </div>
    )
  }

  getContent = () => {
    const { cell, isShiftViewBar } = this.props
    const currentStaffEvent = this._getCurrentStaffEvent(cell.get('staffEvents'))
    const isShiftEvent = this.isShiftEvent(currentStaffEvent)

    const isUnavailable = currentStaffEvent.get('isUnavailable')
    const isPTO = currentStaffEvent.get('isPTO')
    const isDayOff = isPTO || isUnavailable

    if (isShiftViewBar) {
      return this.getShiftViewBarContent()
    }

    if (isShiftEvent) {
      return this.getStaffViewBarContent()
    }
    if (isDayOff) {
      return this.getDayOffContent()
    }
  }

  renderContent = () => {
    const { cell, dayIndex, eventBar, isShiftViewBar } = this.props
    const { resizeStarted, moveStarted } = this.state
    const isEmptyCell = cell.size === 0
    if (isEmptyCell) {
      return null
    }

    const cellStaffEvent = this._getCurrentStaffEvent(cell.get('staffEvents'))
    const isProcessing = cell.get('isProcessing')
    if (isProcessing) {
      return null
    }
    const isPTO = cellStaffEvent.get('isPTO')
    const isUnavailable = cellStaffEvent.get('isUnavailable')

    const isDayOff = isPTO || isUnavailable
    const identityHash = eventBar.id || 'empty'
    return (
      <div
        data-is-event-bar-element={true}
        data-day-cell-index={dayIndex}
        data-cell-identity-hash={identityHash}
        className={classNames('staff-event', {
          'pto-event': isDayOff,
          white: !isDayOff,
          'shift-view': isShiftViewBar
        })}
      >
        <SpillProofDiv
          data-is-event-bar-element={true}
          data-day-cell-index={dayIndex}
          data-cell-identity-hash={identityHash}
          className={classNames(
            'content',
            {
              highlighted: (moveStarted || resizeStarted) && !isDayOff && !isShiftViewBar
            },
            {
              'highlighted-shift-view-bar': (moveStarted || resizeStarted) && isShiftViewBar
            }
          )}
          expandOnHover={true}
          onMouseEnter={({ currentTarget }) => {
            currentTarget.scrollWidth > currentTarget.offsetWidth && this.setState({ hoverExpanded: true })
          }}
          onMouseLeave={() => {
            this.setState({ hoverExpanded: false })
          }}
        >
          {this.getContent()}
        </SpillProofDiv>
      </div>
    )
  }

  getIsAssignmentIcon = (isCancelled) => {
    let icon = ''

    icon = 'icon-dot'
    if (isCancelled) {
      icon = 'svg-icon new-icon-cancelled-shift'
    }
    return icon
  }

  getTypeIcon() {
    const { cell, eventBar, indicators } = this.props

    const { resizeStarted, moveStarted } = this.state
    if ((resizeStarted || moveStarted) && eventBar.type !== 'timeOff') {
      return 'icon-event-update'
    }

    const staffEventId = eventBar.id
    const cellStaffEvent = cell.get('staffEvents').find((staffEvent) => staffEvent.get('id') === staffEventId)
    const isAssignment = !!cellStaffEvent.get('isAssignment')
    const isMeeting = cellStaffEvent.get('isMeeting')
    const isCancelled = !!cellStaffEvent.get('isCancelled')
    const isOnCall = cellStaffEvent.get('isOnCall')
    const isPTO = !!cellStaffEvent.get('isPTO')
    const isUnavailable = cellStaffEvent.get('isUnavailable')
    const isPartialShiftEvent = !!cellStaffEvent.get('isPartialShiftEvent')
    let icon = ''
    if (isAssignment) {
      icon = this.getIsAssignmentIcon(isCancelled)
    }

    if (isMeeting) {
      if (isPartialShiftEvent) {
        icon = 'new-icon-event-white'
      } else {
        icon = 'new-icon-event-black'
      }
    }

    if (isOnCall) {
      icon = isCancelled ? 'svg-icon new-icon-cancelled-shift' : 'icon-indicator-call'
    }
    if (isPTO) {
      icon = 'Icons icon-Money-Sign'
    }
    if (isUnavailable && indicators.get('unavailable')) {
      icon = 'icon-close'
    }
    return icon
  }

  isLocked = (cell, indicators) => {
    const { eventBar } = this.props
    if (eventBar.type === 'timeOff') {
      return
    }
    return (
      indicators.get('locks') &&
      !cell.get('isRequestedToWork') &&
      (cell.get('isMandatoryToWork') || cell.get('isTimeOffNotAllowed'))
    )
  }

  getLockedIconset = (isPTO, iconsSet) => {
    const { resizeStarted, moveStarted } = this.state
    const isHighlighted = moveStarted || resizeStarted
    if (!isPTO) {
      iconsSet.push('icon-recurring')
    } else {
      if (isHighlighted) {
        iconsSet.push('icon-reset-highlighted')
      } else {
        iconsSet.push('icon-reset-black') // needs to change logic
      }
    }
  }

  isRequestedToWork = (cell, indicators) => {
    return indicators.get('available') && cell.get('isRequestedToWork')
  }

  getSetIcons() {
    const { cell, eventBar, indicators } = this.props
    const cellStaffEvent = this._getCurrentStaffEvent(cell.get('staffEvents'))

    const isRequestedToWork = this.isRequestedToWork(cell, indicators)
    const isPTO = cellStaffEvent.get('isPTO')
    const isInfo = indicators.get('info') && cellStaffEvent.get('isExpertiseMismatchEvent')
    const isLocked = this.isLocked(cell, indicators)
    let iconsSet = []
    const { startsDayBefore, endsAtHours } = eventBar
    if (startsDayBefore) {
      eventBar.type === 'timeOff' ? iconsSet.push('icon-arrow-left-black') : iconsSet.push('icon-arrow-left')
    }
    if (endsAtHours > 24) {
      eventBar.type === 'timeOff' ? iconsSet.push('icon-arrow-right-black') : iconsSet.push('icon-arrow-right')
    }

    if (isLocked) {
      this.getLockedIconset(isPTO, iconsSet)
    }

    if (isRequestedToWork) {
      iconsSet.push('icon-indicator-checkmark')
    }

    if (cell.get('isRequestedDayOff') && indicators.get('unavailable')) {
      iconsSet.push('time-off-icon')
    }

    if (isInfo) {
      iconsSet.push('icon-indicator-info')
    }

    if (cellStaffEvent.get('hasNotes') && indicators.get('noteOrExplanation')) {
      iconsSet.push('icon-indicator-note')
    }
    return iconsSet
  }
}

function convertHoursToTime(hours) {
  const hour = Math.floor(hours)
  const minutesFloat = hours - hour
  const minute = Math.floor(minutesFloat * 60)
  const hourFixed = hours > 24 ? hour - 24 : hour
  return `${padStart(hourFixed + '', 2, '0')}:${padStart(minute + '', 2, '0')}`
}
