import { Component } from 'react'
import { Modal } from 'Common/components'
import CreateUpdateRecurringScheduleForm from './CreateUpdateRecurringScheduleForm'
import { navigate } from 'Navigation'
import './recurringSchedule.scss'

export default class CreateUpdateRecurringScheduleModal extends Component {
  hideModal = () => {
    const { match } = this.props
    const { staffRecurringScheduleId } = match.params

    if (staffRecurringScheduleId) {
      return navigate.from.StaffEditRecurringSchedule.to.StaffDetails()
    }
    return navigate.from.StaffCreateRecurringSchedule.to.StaffDetails()
  }

  render() {
    const {
      staffRecurringSchedules,
      match,
      onSubmit,
      staff,
      timeService,
      staffShiftAssignments,
      units,
      staffPositions,
      authProfile,
      homeUnitId,
      shifts,
      unit
    } = this.props
    const { date: usDate } = match.params
    const { staffRecurringScheduleId } = match.params

    const staffRecurringSchedule = staffRecurringSchedules.find(
      (schedule) => schedule.get('id') === staffRecurringScheduleId
    )

    const modalProps = {
      visible: true,
      onClose: this.hideModal,
      width: 588,
      title: 'Recurring Schedule'
    }

    const formProps = {
      onClose: this.hideModal,
      onSubmit,
      staff,
      shifts,
      timeService,
      staffShiftAssignments,
      units,
      unit,
      staffPositions,
      authProfile,
      homeUnitId,
      usDate,
      staffRecurringSchedule
    }

    return (
      <Modal {...modalProps} modalBoxClasses="hx-modal-staff-list">
        <CreateUpdateRecurringScheduleForm {...formProps} />
      </Modal>
    )
  }
}
