import StateController from './StateController'
import { fromJS, Map } from 'immutable'

export default class FilterController extends StateController {
  initializeFilters = () => {
    const { unit } = this.props
    const shiftTypes = unit.get('shiftTypes').map((shiftType) => shiftType.get('id'))
    const roleIds = unit.get('roles').map((role) => role.get('id'))
    this.updateFilter((filters) => filters.merge({ shiftTypes, roleIds }))
  }

  getSettingsMap = () => {
    const { appState } = this.props
    return appState
      .getIn(['authentication', 'facilityUser', 'unitManagerProfile', 'settings'])
      .reduce((settingsMap, item) => {
        const key = item.get('key')
        const value = item.get('value')
        return settingsMap.set(key, value)
      }, Map())
  }

  buildFilters = () => {
    const settingsMap = this.getSettingsMap()
    return Object.keys(filterPropPaths)
      .map((name) => ({ name, path: filterPropPaths[name] }))
      .reduce((filter, item) => {
        const { path } = item
        const filterPath = path.split('_')
        const settingPath = `calendar_filters_${path}`
        const value = settingsMap.get(settingPath) || defaultFilters.getIn(filterPath)
        return filter.setIn(filterPath, value)
      }, defaultFilters)
  }

  saveFilters = (filters) => {
    const { authStore } = this.props
    const settings = Object.keys(filterPropPaths)
      .map((name) => ({ name, path: filterPropPaths[name] }))
      .map((item) => {
        const { path } = item
        const filterPath = path.split('_')
        const value = filters.getIn(filterPath)
        const settingPath = `calendar_filters_${path}`
        return { key: settingPath, value }
      })

    if (settings.length > 0) {
      authStore.updateUnitManagerProfileSettings(settings)
    }
  }

  updateFilter = (updater) =>
    this.setState(({ filters }) => {
      const newFilters = updater(filters)
      return { filters: newFilters }
    })

  updateFilterRole = (role) => (updater) =>
    this.setState(({ filters }) => {
      const newFilters = filters.update(role, updater)
      return {
        filters: newFilters
      }
    })

  onChangeDisplayOptions = (displayOptions) => {
    this.setState(({ filters }) => {
      const prevSortBy = filters.getIn(['displayOptions', 'staff', 'sortBy'])
      const sortBy = displayOptions.getIn(['staff', 'sortBy'])
      if (sortBy !== prevSortBy) {
        displayOptions = displayOptions.setIn(['staff', 'orderBy'], 'asc')
      }

      filters = filters.set('displayOptions', displayOptions)
      this.saveFilters(filters)
      return { filters }
    })
  }
}

const filterPropPaths = {
  view: 'displayOptions_view',
  displayNameAs: 'displayOptions_staff_displayNameAs',
  groupBy: 'displayOptions_staff_groupBy',
  sortBy: 'displayOptions_staff_sortBy',
  orderBy: 'displayOptions_staff_orderBy',
  viewCoverageAs: 'displayOptions_coverage_viewCoverageAs'
}

const defaultFilters = fromJS({
  showMergedShift: false,
  hideEmptyShifts: false,
  hideEmptyRoles: false,
  filterBySearch: '',
  shiftTypes: [],
  roleIds: [],
  collapsed: { 'secondary-staff': true }, // key => roleId
  indicators: {
    noteOrExplanation: true,
    availabilityAndPreferences: true,
    available: true,
    locks: true,
    unavailable: true,
    info: true,
    onCall: true
  },
  dayViewFilters: {
    classOrientation: true,
    floatOutside: true,
    cancelledShift: true,
    timeOff: true,
    pto: true,
    requestOff: true
  },
  displayOptions: {
    view: 'coverage',
    staff: {
      displayNameAs: 'firstNameLastName',
      groupBy: 'employment',
      sortBy: 'firstName',
      orderBy: 'asc'
    },
    coverage: {
      viewCoverageAs: 'scheduledStaff'
    }
  }
})
