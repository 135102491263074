import { memo, useEffect, useState } from 'react'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { List, Map } from 'immutable'
import { PopUp, ImmutableSelect } from 'Manager/components'
import './ShiftViewCellContextMenu.scss'
import StaffRow from './StaffRow'
import { t } from 'i18n'

const ShiftViewCellContextMenu = (props) => {
  const {
    popupProps,
    popupProps: { targetElement, eventParameters },
    scrollTop,
    actionController,
    hidePopup,
    getViewModel,
    getStaffEventDetails,
    eligibleUnits,
    unit: currentUnit
  } = props
  const style = { width: 300 }

  const [roleName, setRoleName] = useState('')
  const [shiftName, setShiftName] = useState('')
  const [allStaffs, setAllStaffs] = useState(List())
  const [staffs, setStaffs] = useState(List())
  const [staff, setStaff] = useState(Map())
  const [selectedUnit, setSelectedUnit] = useState('')

  useEffect(() => {
    const roleIndex = eventParameters.get('roleIndex')
    const shiftIndex = eventParameters.get('shiftIndex')
    const shiftId = eventParameters.get('shiftId')
    let staffs = List([])
    let shiftValue
    const data = getViewModel({}, false, true)
    const roleData = data.filter((item) => item.get('kind') === 'role') || List()
    const role = roleData.filter((e) => e.get('roleIndex') === roleIndex)?.first()
    const roleId = role.get('id')
    const roleName = role.get('name')
    const shiftData = roleData.find((item) => item.get('id') === roleId)
    const filteredShiftData = shiftData
      ? shiftData.get('shifts').filter((item) => item.get('shiftIndex') === shiftIndex)
      : List()
    if (filteredShiftData.size) {
      const shiftData = filteredShiftData.first()
      const shiftName = `${shiftData?.get('name')} (${shiftData?.get('range')})`
      setShiftName(shiftName || '')
      shiftValue = List([Map({ name: shiftName, id: shiftId })])
    }
    data.forEach((e) => {
      const staffList = e.get('staff')
      if (staffList?.size) {
        staffList?.forEach((staff) => {
          if (staff.get('fullName')) {
            staffs = [...staffs, staff].sort((a, b) => a.get('fullName')?.localeCompare(b.get('fullName')))
          }
        })
      }
    })
    setRoleName(roleName || '')
    setStaff(Map())
    setAllStaffs(staffs || List())
    const defaultUnit = eligibleUnits.find((unit) => unit.get('id') === currentUnit?.get('id'))
    if (defaultUnit) {
      setSelectedUnit(defaultUnit.get('id'))
    }
  }, [targetElement, eventParameters, getViewModel, eligibleUnits, currentUnit])

  useEffect(() => {
    filterStaffs()
  }, [selectedUnit, allStaffs, currentUnit])

  const handleUnitChange = (e) => {
    setStaff(Map())
    setSelectedUnit(e.target.value)
  }

  const handleStaffSelection = (e, selectedStaffId) => {
    const staff = staffs.find((item) => item.get('id') === selectedStaffId)
    setStaff(staff)
  }

  const removeStaffSelection = () => {
    setStaff(Map())
  }

  const handleAddToShift = () => {
    let { eventParameters } = popupProps
    eventParameters = eventParameters.set('staff', staff)
    getStaffEventDetails({ staff, addStaffToOnCallShift: true })
    actionController.createStaffEventsForSelection(eventParameters)
    hidePopup()
  }

  const isStaffSelected = !!staff.size

  const filterStaffs = () => {
    const filteredStaffs = allStaffs.filter((staff) => {
      return staff.getIn(['staffProfile', 'eligibleUnits'])?.some((unit) => unit.get('homeUnitId') === selectedUnit)
    })
    setStaffs(filteredStaffs)
  }

  return (
    <PopUp {...popupProps} scrollTop={scrollTop} className="hx-cell-details">
      <div style={style}>
        <div className="bb1">
          <section className="p15">
            <div className="row pt5">
              <div className="form-item col col-2">
                <label>{t('calendar.role')}</label>
              </div>
              <div className="form-item col col-10">{roleName}</div>
            </div>
            <div className="row pt5">
              <div className="form-item col col-2">
                <label>{t('calendar.shift')}</label>
              </div>
              <div className="form-item col col-10">{shiftName}</div>
            </div>
            <div className="row pt5">
              <div className="form-item col col-2">
                <label>{t('calendar.unit')}</label>
              </div>
              <div className="form-item col col-10">
                <ImmutableSelect
                  options={eligibleUnits}
                  value={selectedUnit}
                  placeholder={true}
                  disabled={false}
                  onChange={handleUnitChange}
                />
              </div>
            </div>
            <div className={`row ${isStaffSelected ? 'pt10' : 'pt5'}`}>
              <div className="form-item col col-2">
                <label>{t('calendar.staff')}</label>
              </div>
              {!isStaffSelected && (
                <div className="form-item col col-10">
                  <input type="text" placeholder="Please Select" disabled />
                </div>
              )}
            </div>
            <div className="row pt5">
              <div className="form-item col col-2" />
              <div className="form-item col col-10">
                {staffs.length === 0 ? (
                  <div>{t('calendar.no_staff_for_unit_message')}</div>
                ) : (
                  <div className={`staffBox ${classNames({ hideStaffBox: isStaffSelected })}`}>
                    {staffs.map((staff) => (
                      <StaffRow key={staff.get('id')} staff={staff} handleStaffSelection={handleStaffSelection} />
                    ))}
                  </div>
                )}
                {isStaffSelected && (
                  <div className={`staffBox ${classNames({ selectedStaff: isStaffSelected })}`}>
                    <StaffRow
                      staff={staff}
                      removeStaffSelection={removeStaffSelection}
                      isStaffSelected={isStaffSelected}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
        <div className="p10 row">
          <div className="col-6"></div>
          <div className="col-6 addShiftButton">
            <sh-button
              disabled={!isStaffSelected ? true : undefined}
              onClick={handleAddToShift}
              label="Add to shift"
              color="tertiary"
              size="full-width"
            ></sh-button>
          </div>
        </div>
      </div>
    </PopUp>
  )
}

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}

export default memo(ShiftViewCellContextMenu, areEqual)
