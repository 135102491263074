// TODO: Decouple this into individual files for better visibility.
import { List, Range } from 'immutable'
import moment from 'moment'
import { sortBy } from 'lodash'
import classNames from 'classnames'
import GQLDataLoader from './GQLDataLoader'

export { formatPhoneNumber } from './formatPhoneNumber'
export { mockShiftDays } from './buildMockShiftDays'

const { toString } = Object.prototype

const findParentElement = (el, key, value) => {
  do {
    if ((key === 'classList' && el[key] && el[key].contains(value)) || el[key] === value) {
      return el
    }
  } while ((el = el.parentNode))
  return null
}

const getStaffStrategyProperties = (strategy, staffScheduled, targetCover, isStaffMinutesMismatch) => {
  let count = Math.abs(strategy === 'scheduledStaff' ? staffScheduled : targetCover - staffScheduled)
  const isOverstaffed = staffScheduled > targetCover
  const isUnderstaffed = staffScheduled < targetCover
  const isBalanced = staffScheduled === targetCover
  const isDelta = strategy === 'staffDelta'
  const className = classNames({
    'understaffed-triangle': isUnderstaffed && isDelta && !isStaffMinutesMismatch,
    'overstaffed-triangle': isOverstaffed && isDelta && !isStaffMinutesMismatch,
    understaffed: isUnderstaffed && !isDelta && !isStaffMinutesMismatch,
    overstaffed: isOverstaffed && !isDelta && !isStaffMinutesMismatch,
    partiated: isStaffMinutesMismatch
  })
  const staffDelta = Math.abs(targetCover - staffScheduled)

  return { count, staffDelta, className, isBalanced, isOverstaffed, isUnderstaffed }
}

function getOffsetLeft(elem) {
  let offsetLeft = 0

  do {
    if (!isNaN(elem.offsetLeft)) {
      offsetLeft += elem.offsetLeft
    }
  } while ((elem = elem.offsetParent))

  return offsetLeft
}

function getOffsetTop(elem, rootElementClass) {
  let offsetTop = 0

  do {
    if (rootElementClass && elem.classList.contains(rootElementClass)) {
      break
    }

    if (!isNaN(elem.offsetTop)) {
      offsetTop += elem.offsetTop
    }
  } while ((elem = elem.offsetParent))

  return offsetTop
}

const chunk = (list, chunkSize = 1) => {
  return Range(0, list.count(), chunkSize).map((chunkStart) => list.slice(chunkStart, chunkStart + chunkSize))
}

//TODO: refactor this keyBy to reduce into a Map
// list.reduce((memo, value) => memo.set(value.get(field), value), Map());
const keyBy = (list, field) => {
  return list.groupBy((listItem) => listItem.get(field)).map((listItems) => listItems.get(0))
}

const dateTimeRange = (dateTime1, dateTime2, timeService) => {
  let startsAt = timeService.timeMoment(dateTime1)
  let endsAt = timeService.timeMoment(dateTime2)
  const isReversed = startsAt.isAfter(endsAt)

  if (startsAt.isAfter(endsAt)) {
    startsAt = timeService.timeMoment(dateTime2)
    endsAt = timeService.timeMoment(dateTime1)
  }

  const daysCount = Math.ceil(endsAt.diff(startsAt, 'hours') / 24)
  const result = []

  for (let days = 0; days <= daysCount; days++) {
    // TODO: should handle daylight saving period
    const dateTime = startsAt.clone().add(days, 'day')
    result.push(dateTime.toISOString())
  }

  return isReversed ? result.reverse() : result
}

// TODO optimize moment here
const nextDateTime = (dateTime) => {
  return moment(dateTime).add(24, 'hours').toISOString()
}

const previousDateTime = (dateTime) => {
  return moment(dateTime).add(-24, 'hours').toISOString()
}

const iterateThroughDateRange = (startDate, endDate, timeService) => {
  const startDateMoment = timeService.timeMoment(startDate)
  const endDateMoment = timeService.timeMoment(endDate)
  const daysCount = Math.ceil(endDateMoment.diff(startDateMoment, 'days'))

  return (method) => {
    for (let iterator = 0; iterator < daysCount; iterator++) {
      const dayMoment = startDateMoment.clone().add(iterator, 'day')

      method(dayMoment, iterator)
    }
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function uniqListValues(list) {
  return list.toSet().toList()
}

function uniqValues(array) {
  return array.filter((value, index, arr) => arr.indexOf(value) === index)
}

function isSorted(array, direction = 'asc') {
  return array.reduce((result, value, index, arr) => {
    if (result === false) {
      return false
    }

    const prevValue = arr[index - 1]
    if (prevValue === undefined) {
      return undefined
    }

    const isValueGtEPrev = prevValue <= value
    const isValueLtEPrev = prevValue >= value
    const isDescending = direction === 'desc'

    return isDescending ? isValueLtEPrev : isValueGtEPrev
  })
}

function mergePeriods(periods, timeService) {
  if (periods.length === 0) {
    return []
  }

  const sortedPeriods = sortBy(periods, 'startsAt')
  const mergedPeriods = []
  let startsAt, endsAt, prevPeriod

  sortedPeriods.forEach((period) => {
    if (!startsAt) {
      ;({ startsAt, endsAt } = period)
    } else {
      const isPeriodNextToPrev = isPeriodConsistent(prevPeriod, period, timeService)
      if (isPeriodNextToPrev) {
        ;({ endsAt } = period)
      } else {
        mergedPeriods.push({ startsAt, endsAt })
        ;({ startsAt, endsAt } = period)
      }
    }

    prevPeriod = period
  })

  mergedPeriods.push({ startsAt, endsAt })

  return mergedPeriods
}

function isPeriodConsistent(prevPeriod, period, timeService) {
  const { startsAt } = period
  const { endsAt: prevEndsAt } = prevPeriod

  if (startsAt === prevEndsAt) {
    return true
  }

  const startsAtMoment = timeService.timeMoment(startsAt)
  return startsAtMoment.isSameOrBefore(prevEndsAt)
}

function isFunction(object) {
  const isNormalFunction = toString.call(object) === '[object Function]'
  const isAsyncFunction = toString.call(object) === '[object AsyncFunction]'
  return isNormalFunction || isAsyncFunction
}

function lastIndexOf(array, condition) {
  const index = array.slice().reverse().findIndex(condition)
  return index >= 0 ? array.length - 1 - index : index
}

function memorizeObject(originalObject, getMemorizedValue) {
  return Object.keys(originalObject).reduce(
    (object, methodName) => {
      const method = originalObject[methodName]
      const isNormalFunction = toString.call(method) === '[object Function]'
      const isAsyncFunction = toString.call(method) === '[object AsyncFunction]'

      if (!isNormalFunction && !isAsyncFunction) {
        return object
      }

      object[methodName] = (...args) => {
        const cachedValue = getMemorizedValue(methodName, args)
        return cachedValue || method.call(originalObject, ...args)
      }

      return object
    },
    Object.assign({}, originalObject)
  )
}

function isEmptyObject(object) {
  return Object.keys(object).length === 0
}

function isUsDate(date) {
  const usDateRegExp = /\d{2}-\d{2}-\d{4}/
  return usDateRegExp.test(date)
}

export const isEnabledFeature = (state, featureName) => {
  const enabledFeatures = state.getIn(['authentication', 'features']) || List()
  return !!enabledFeatures.find((feature) => feature.get('feature') === featureName)
}

export const isFacilityEnabledFeature = (state, featureName) => {
  const enabledFeatures = state.getIn(['authentication', 'facility', 'configuration', 'features']) || List()
  const feature = enabledFeatures.find((f) => f.get('name') === featureName)
  return feature ? ['read', 'write'].includes(feature.get('state')) : false
}

function getDateRangeEdges(dates) {
  const datesSorted = dates.sort()
  const firstDate = datesSorted[0]
  const lastDate = dates[dates.length - 1]

  return [firstDate, lastDate]
}

function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}

function moveArrayItem(prevIndex, nextIndex, array) {
  const item = array[prevIndex]
  if (!item) {
    return array
  }

  const arrayWithoutItem = array.filter((_item, index) => index !== prevIndex)
  return insertItemIntoArray(item, nextIndex, arrayWithoutItem)
}

function findLastIndex(array, findCondition) {
  const indexReverted = array.slice().reverse().findIndex(findCondition)
  if (indexReverted === -1) {
    return indexReverted
  }

  return array.length - 1 - indexReverted
}

function insertItemIntoArray(item, index, array) {
  const newArray = array.slice()
  newArray.splice(index, 0, item)
  return newArray
}

function cumulativeOffset(element) {
  let top = 0
  let left = 0

  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0
    element = element.offsetParent
  } while (element)

  return { top, left }
}

function getHeight(selector) {
  const element = document.querySelector(selector)
  return element ? element.getBoundingClientRect().height : 0
}

function calculateTotalTopHeight() {
  const accessBarHeight = getHeight('sh-access-bar')
  const topNavigationHeight = getHeight('.hx-top-navigation')
  const mainNavigationHeight = getHeight('.hx-main-navigation')
  return accessBarHeight + topNavigationHeight + mainNavigationHeight
}

export {
  findLastIndex,
  moveArrayItem,
  isObject,
  cumulativeOffset,
  GQLDataLoader,
  isUsDate,
  isSorted,
  findParentElement,
  insertItemIntoArray,
  chunk,
  keyBy,
  mergePeriods,
  uniqValues,
  dateTimeRange,
  nextDateTime,
  previousDateTime,
  getOffsetLeft,
  getOffsetTop,
  iterateThroughDateRange,
  capitalizeFirstLetter,
  uniqListValues,
  isPeriodConsistent,
  isFunction,
  lastIndexOf,
  memorizeObject,
  isEmptyObject,
  getDateRangeEdges,
  getStaffStrategyProperties,
  calculateTotalTopHeight
}
