import { SpillProofDiv } from 'Common/components'
import { getShiftTimeRange } from 'Staff/Calendar/utils'
import { StaffIdentifier } from 'Staff/Common'
import { Map, List } from 'immutable'
import { useEffect, useState } from 'react'
import stores from 'stores'

const { staffCalendarStore }: any = stores

export interface IConcurrentStaffProps {
  staffEvent: Map<string, any>
  publicStaffMap: Map<string, Map<string, any>>
  shiftsMap: Map<string, Map<string, any>>
  timeService: any
}

export default function ConcurrentStaff({ staffEvent, publicStaffMap, shiftsMap, timeService }: IConcurrentStaffProps) {
  const [concurrentStaffEvents, setConcurrentStaffEvents] = useState<any>(List())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    const fetchConcurrentStaffEvents = async (iStaffEvent: Map<string, any>) => {
      setIsLoading(true)
      const data = await staffCalendarStore.getConcurrentStaffEventsForStaffEvent(iStaffEvent.get('id'))
      setIsLoading(false)
      setConcurrentStaffEvents(data)
    }
    fetchConcurrentStaffEvents(staffEvent)
  }, [staffEvent])
  if (staffEvent.get('type') !== 'assignment' && staffEvent.get('type') !== 'onCall') {
    return null
  }
  if (isLoading) {
    return (
      <div className="row col-12 pt20">
        <sh-spinner />
      </div>
    )
  }
  const assignments =
    concurrentStaffEvents?.filter(
      (iStaffEvent: Map<string, any>) =>
        iStaffEvent.get('type') === 'assignment' || iStaffEvent.get('type') === 'onCall'
    ) || List()
  const sameShiftConcurrentAssignments = assignments.filter(
    (concurrentAssignment: Map<string, any>) => concurrentAssignment.get('shiftId') === staffEvent.get('shiftId')
  )
  const otherShiftConcurrentAssignmentsByShiftId = assignments
    .filter(
      (concurrentAssignment: Map<string, any>) => concurrentAssignment.get('shiftId') !== staffEvent.get('shiftId')
    )
    .groupBy((concurrentAssignment: Map<string, any>) => concurrentAssignment.get('shiftId'))

  const otherShiftConcurrentStaffByShiftId = otherShiftConcurrentAssignmentsByShiftId.map(
    (concurrentAssignments: List<Map<string, any>>) =>
      concurrentAssignments
        .map((iStaffEvent) => publicStaffMap.get(iStaffEvent?.get('userId')))
        .filter((staff) => !!staff)
        .toOrderedSet()
  )

  const sameShiftConcurrentStaff = sameShiftConcurrentAssignments
    .map((iStaffEvent: Map<string, any>) => publicStaffMap.get(iStaffEvent.get('userId')))
    .filter((staff: Map<string, any>) => !!staff)
    .toOrderedSet()

  const sameShiftConcurrentStaffUserIdentifiers = sameShiftConcurrentStaff.map((publicStaff: Map<string, any>) => (
    <StaffIdentifier publicStaff={publicStaff} />
  ))

  const otherShiftConcurrentStaffUserIdentifiers: any = []
  otherShiftConcurrentStaffByShiftId.forEach((otherShiftConcurrentStaff: List<Map<string, any>>, shiftId: string) => {
    const shift = shiftsMap.get(shiftId)
    if (!shift) {
      return
    }
    const shiftTimeRange = getShiftTimeRange(shift, timeService)
    otherShiftConcurrentStaffUserIdentifiers.push(
      <div className="pt10">
        <SpillProofDiv>
          <sh-text size="body-2" color="primary">
            {shift.get('name')}
          </sh-text>
        </SpillProofDiv>
        <SpillProofDiv>
          <sh-text size="body-2" color="secondary">
            {shiftTimeRange}
          </sh-text>
        </SpillProofDiv>
      </div>
    )
    otherShiftConcurrentStaff.forEach((publicStaff) => {
      if (publicStaff) {
        otherShiftConcurrentStaffUserIdentifiers.push(<StaffIdentifier publicStaff={publicStaff} />)
      }
    })
  })

  const emptyStateMessageForSameShift = 'No staff concurrently working in your shift'
  const emptyStateMessageForOtherShift = 'No staff concurrently working in other shifts'

  return (
    <div className="row col-12 between pt20">
      <div className="col-5">
        <div className="pb10">
          <sh-text size="title-1">Your Shift</sh-text>
        </div>
        {sameShiftConcurrentStaffUserIdentifiers.size ? (
          sameShiftConcurrentStaffUserIdentifiers.toArray()
        ) : (
          <sh-empty-state icon="patient" label={emptyStateMessageForSameShift} />
        )}
      </div>
      <div className="col-1">
        <sh-divider vertical />
      </div>
      <div className="col-5">
        <sh-text size="title-1">Other Shifts</sh-text>
        {otherShiftConcurrentStaffUserIdentifiers.length ? (
          otherShiftConcurrentStaffUserIdentifiers
        ) : (
          <sh-empty-state icon="patient" label={emptyStateMessageForOtherShift} />
        )}
      </div>
    </div>
  )
}
