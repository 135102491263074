import { Component, createRef } from 'react'
import classNames from 'classnames'
import { curry } from 'i18n'
import { omit } from 'lodash'
import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import CustomPhoneNumberInput from '@humanics/he-react-common/lib/components/Form/CustomPhoneNumberInput'
import CustomDateInput from '@humanics/he-react-common/lib/components/Form/CustomDateInput'
import form from './formDefinitions'
import FormController from './FormController'
import './EditStaff.scss'
import { EMAIL_ERROR_CODES, PHONE_NUMBER_ERROR_CODES } from '../../../utils/GraphQLErrors'
import FormActionBar from 'Manager/Staff/StaffDetails/CreateEditExpertiseModals/FormActionBar'
import { Avatar } from 'Manager/components'
import { addWarningBanner } from 'stores'

const t = curry('staff.')

export default class EditStaffForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form,
      isLoading: false,
      showEndsAt: false,
      selectedFile: null,
      imagePreview: null
    }
    this.fileInputRef = createRef()
    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.controller = new FormController(this)
  }

  componentWillUnmount() {
    const form = this.controller._resetForm(this.state.form)
    this.controller.setState({ form })
  }

  componentDidMount() {
    this.controller.updateForm()
  }

  componentDidUpdate(prevProps) {
    const { staff } = this.props
    const { staff: prevStaff } = prevProps
    const isStaffChanged = staff !== prevStaff

    if (isStaffChanged) {
      this.controller.updateForm()
    }
  }

  handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const isValidFile = this.validateFile(file)
      if (!isValidFile) {
        alert('Invalid File Type: Please upload only images.')
        return
      }
      const reader = new FileReader()

      reader.onloadend = () => {
        this.setState({ selectedFile: file, imagePreview: reader.result })
      }

      reader.readAsDataURL(file)
    }
  }

  validateFile = (inputFile) => {
    return inputFile.type.startsWith('image')
  }

  handleFileButtonClick = () => {
    this.fileInputRef.current.click()
  }

  submitProfilePicture = async (imageFile) => {
    const { facilityId, accountsUri } = this.props

    const handleImageUploadError = () => {
      addWarningBanner({ message: 'Image upload error' })
    }

    const uploadImageUrl = `${accountsUri}/api/self/profile/avatar`
    let bodyFormData = new FormData()
    bodyFormData.append('file', imageFile)
    const options = {
      body: bodyFormData,
      method: 'POST',
      credentials: 'include',
      headers: {
        Scope: facilityId
      }
    }
    try {
      const response = await fetch(uploadImageUrl, options)

      if (!(response.status === 200)) {
        throw new Error('Image upload failed. Please try again.')
      }
    } catch (error) {
      handleImageUploadError()
    }
  }

  onClickSubmit = async (e) => {
    e.preventDefault()

    const { onSubmit, onClose, isEdit } = this.props
    let data = this.controller.serializeForm()
    data.maximumNumberOfHoursPerWeek = parseFloat(data.maximumNumberOfHoursPerWeek)
    data.isStaffOnly = true
    if (isEdit) {
      data = omit(data, ['email', 'phoneNumber'])
    }
    const hasError = !this.controller.validateForm(true)
    if (hasError) {
      return
    }

    try {
      this.setState({ isLoading: true })

      if (this.state.selectedFile) {
        await this.submitProfilePicture(this.state.selectedFile)
      }

      await onSubmit(data)
      this.setState({ isLoading: false })
      onClose()
    } catch (error) {
      const { form } = this.state
      let emailError, phoneError
      /*
      Error codes (except for "COR-53", which is the one working) were retrieved from:
      https://gitlab.com/humanics/he-core/blob/develop/config/errors.yaml
      However, they don't seem to react properly.
      the error on this endpoint response comes in the form of err.err.graphQLErrors[...errors]
      */
      if (error?.graphQLErrors) {
        emailError = error.graphQLErrors.some((err) => EMAIL_ERROR_CODES.includes(err.code))
        phoneError = error.graphQLErrors.some((err) => PHONE_NUMBER_ERROR_CODES.includes(err.code))
      }

      if (emailError) {
        form.email.inlineError = t('auth.email_already_used')
      }
      if (phoneError) {
        form.phoneNumber.inlineError = t('auth.phone_already_used')
      }

      this.setState({ isLoading: false, form })
      onClose()
    }
  }

  render() {
    const { onClose, timeService, isEdit, staff, userId } = this.props
    const { showEndsAt, isLoading, selectedFile, imagePreview } = this.state
    const profile = staff?.get('profile')
    const form = this.controller.getFormProperties()
    const isFormValid = this.controller.isFormValid()

    return (
      <form className={'form mb0 create-edit-staff-form'}>
        <div className="p2 pb0 row">
          <div className="hx-staff-profile-box row w100 ">
            <Avatar
              initialsClass="hx-staff-initials rounded hx-staff-profile-picture-size"
              avatarClass="hx-staff-avatar rounded hx-staff-profile-picture-size"
              profile={profile}
              userId={userId}
              preview={imagePreview}
            />
            <input
              ref={this.fileInputRef}
              type="file"
              onChange={this.handleFileChange}
              style={{ display: 'none' }}
              accept="image/*"
            />
            <div className="avatar-button-container">
              <sh-button
                label={selectedFile ? 'Change Profile Picture' : 'Upload Profile Picture'}
                size="auto"
                color="secondary"
                onClick={this.handleFileButtonClick}
              />
            </div>
          </div>
          <StringInputField
            {...form.firstName}
            onChange={(e) => this.controller.setFieldValue(e, 'firstName')}
            onBlur={() => this.controller.validateField('firstName')}
          />

          <StringInputField
            {...form.lastName}
            onChange={(e) => this.controller.setFieldValue(e, 'lastName')}
            onBlur={() => this.controller.validateField('lastName')}
          />

          <StringInputField
            {...form.email}
            onChange={(e) => this.controller.setFieldValue(e, 'email')}
            onBlur={() => this.controller.validateField('email')}
            disabled={true}
          />

          <CustomPhoneNumberInput
            {...form.phoneNumber}
            onChange={(e) => this.controller.setFieldValue(e, 'phoneNumber')}
            onBlur={() => this.controller.validateField('phoneNumber')}
            disabled={true}
          />

          <CustomPhoneNumberInput
            {...form.phoneNumber1}
            onChange={(e) => this.controller.setFieldValue(e, 'phoneNumber1')}
            onBlur={() => this.controller.validateField('phoneNumber1')}
            disabled={true}
          />

          <CustomPhoneNumberInput
            {...form.phoneNumber2}
            onChange={(e) => this.controller.setFieldValue(e, 'phoneNumber2')}
            onBlur={() => this.controller.validateField('phoneNumber2')}
            disabled={true}
          />

          <StringInputField
            {...form.homeUnitId}
            onChange={(e) => this.controller.setFieldValue(e, 'homeUnitId')}
            disabled={true}
          />
          <StringInputField
            {...form.position}
            onChange={(e) => this.controller.setFieldValue(e, 'position')}
            disabled={true}
          />

          <StringInputField
            {...form.employmentType}
            onChange={(e) => this.controller.setFieldValue(e, 'employmentType')}
            disabled={true}
          />

          <StringInputField
            {...form.maximumNumberOfHoursPerWeek}
            onChange={(e) => this.controller.setFieldValue(e, 'maximumNumberOfHoursPerWeek')}
            disabled={true}
          />

          <StringInputField
            {...form.availabilityType}
            onChange={(e) => this.controller.setFieldValue(e, 'availabilityType')}
            disabled={true}
          />

          <CustomDateInput
            {...form.startsAt}
            timeService={timeService}
            onChange={(e) => this.controller.setFieldValue(e, 'startsAt')}
            disabled={true}
          />

          {isEdit && (
            <>
              <CustomDateInput
                {...form.endsAt}
                formItemClasses={classNames(form.endsAt.formItemClasses, {
                  hide: !(form.endsAt.value || showEndsAt)
                })}
                timeService={timeService}
                onChange={(e) => this.controller.setFieldValue(e, 'endsAt')}
                disabled={true}
              />

              <div
                className={classNames({
                  'form-item col-6 pr30': true,
                  hide: form.endsAt.value || showEndsAt
                })}
              >
                <label>
                  <span className="form-label">&nbsp;</span>
                </label>
                <input
                  className="form-control input center-align"
                  placeholder="End Date"
                  type="text"
                  onClick={() => this.setState({ showEndsAt: true })}
                  disabled={true}
                />
              </div>
            </>
          )}

          <StringInputField
            {...form.managerFullName}
            onChange={(e) => this.controller.setFieldValue(e, 'managerFullName')}
            onBlur={() => this.controller.validateField('managerFullName')}
            disabled={true}
          />
        </div>
        <footer className="_footer">
          <FormActionBar
            submitLabel="Save"
            isSubmitLoading={isLoading}
            isSubmitDisabled={!isFormValid}
            onSubmit={this.onClickSubmit}
            onCancel={onClose}
          />
        </footer>
      </form>
    )
  }
}
