import { useState, useEffect } from 'react'
import { Map, List, fromJS } from 'immutable'
import Header from './Header'
import Item from './Item'
import { calculateTotalTopHeight } from 'utils'

const calculatePopupStyle = (popupProps) => {
  const { targetElement = { offsetLeft: 0, clientWidth: 0 }, width, viewPort } = popupProps

  const viewPortTopOffset = calculateTotalTopHeight()
  const popupHeight = 150
  const overflowThreshold = 0

  const isWindowOverflow = window.innerHeight - viewPortTopOffset - viewPort.top - popupHeight < overflowThreshold
  const topOffset = isWindowOverflow ? -110 : 0

  const maxWidth = window.innerWidth - width - targetElement.clientWidth
  const left = targetElement.offsetLeft < maxWidth ? width : -1 * width + 1

  return { left: `${left}px`, top: `${topOffset}px` }
}

export default function ShiftsSubmenu({
  popupProps = {},
  onClickHandler,
  primaryShift,
  secondaryShifts,
  onCallShifts,
  eventVariants,
  _cellSubIndex,
  cell
}) {
  const [eventForShift, setEventForShift] = useState(Map())

  const style = calculatePopupStyle(popupProps)

  const hasAssignmentVariant = !!eventVariants.find((variant) => variant.get('id') === 'assignment')
  const hasMeetingVariant = !!eventVariants.find((variant) => variant.get('id') === 'meeting')
  const hasOnCallVariant = !!eventVariants.find((variant) => variant.get('id') === 'onCall')

  const hasPrimaryShift = hasAssignmentVariant && !!primaryShift
  const hasSecondaryShifts = hasAssignmentVariant && secondaryShifts.size > 0
  const hasOnCallShifts = hasOnCallVariant && onCallShifts.size > 0
  const hasMeetingEvent = hasMeetingVariant && !!primaryShift

  useEffect(() => {
    const cellStaffEvents = cell.get('staffEvents') || List()
    let newEventForShift = {}
    cellStaffEvents.forEach((e) => {
      const staffEventShiftId = e.get('shiftId')
      const staffEventId = e.get('id')
      newEventForShift[staffEventShiftId] = staffEventId
    })
    setEventForShift(fromJS(newEventForShift))
  }, [cell])

  const renderShift = (shift) => {
    const eventVariantId = 'assignment'
    const shiftId = shift.get('id')
    const eventParameters = shift.merge({ eventVariantId, shiftId })
    return (
      <Item
        key={shiftId}
        className="shift-item"
        isCheckbox={true}
        isChecked={!!eventForShift.get(shiftId)}
        title={shift.get('name')}
        onClick={onClickHandler(eventParameters, eventForShift.get(shiftId))}
      />
    )
  }

  const renderMeetingEvent = () => {
    const startTime = primaryShift.get('startTime')
    const unitId = primaryShift.get('unitId')
    const length = primaryShift.get('length')
    const eventVariantId = 'meeting'
    const eventParameters = Map({ startTime, unitId, length, eventVariantId })

    return (
      <Item key={eventVariantId} className="shift-item" title={'Event'} onClick={onClickHandler(eventParameters)} />
    )
  }

  const submenuContents = []

  if (hasPrimaryShift) {
    submenuContents.push([
      <Header key="primary-shifts-header" title="Primary Shift" iconClassName="icon-dot rock-blue" />,
      renderShift(primaryShift)
    ])
  }

  if (hasSecondaryShifts) {
    if (submenuContents.length > 0) {
      submenuContents.push(<hr key="secondary-shifts-separator" className="visible" />)
    }
    submenuContents.push([
      <Header key="secondary-shifts-header" title="Secondary Shifts" iconClassName="icon-dot rock-blue" />,
      ...secondaryShifts.map(renderShift)
    ])
  }

  if (hasOnCallShifts) {
    if (submenuContents.length > 0) {
      submenuContents.push(<hr key="on-call-shifts-separator" className="visible" />)
    }
    submenuContents.push([
      <Header key="on-call-shifts-header" title="On Call Shifts" iconClassName="icon-indicator-call rock-blue" />,
      ...onCallShifts.map(renderShift)
    ])
  }

  if (hasMeetingEvent) {
    if (submenuContents.length > 0) {
      submenuContents.push(<hr key="meeting-event-separator" className="visible" />)
    }
    submenuContents.push(renderMeetingEvent())
  }

  return (
    <div data-testid="shifts-submenu" className="submenu" style={style}>
      {submenuContents}
    </div>
  )
}
