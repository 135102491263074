import { Map } from 'immutable'
import { t } from 'i18n'
//TODO: Move this to Staff main layout level from Staff calendar layout level

type StaffEventDisplayAttributes = {
  icon: string
  color: string
  iconColor?: string
}

export type CalendarItemType = 'timeOff' | 'block' | 'assignment' | 'onCall' | 'shiftSwap'

type StaffEventDisplayAttributesByType = {
  [key: string]: StaffEventDisplayAttributes
}

const staffEventDisplayAttributesByType: StaffEventDisplayAttributesByType = {
  timeOff: {
    icon: 'time',
    color: 'rgba(76, 0, 211, 0.22)'
  },
  block: {
    icon: 'time',
    color: 'rgba(76, 0, 211, 0.22)'
  },
  assignment: {
    icon: 'calendar',
    color: 'rgba(108, 174, 255, 0.3)'
  },
  onCall: {
    icon: 'calendar',
    color: 'rgba(108, 174, 255, 0.3)'
  },
  shiftSwap: {
    color: '#d25b13',
    icon: 'in-progress',
    iconColor: 'white'
  },
  staffOnTimeOff: {
    icon: 'time',
    color: 'rgba(240, 93, 143, 0.3)'
  }
}

export function getStaffEventDisplayAttributes(myStaffEvent: Map<string, any>) {
  return staffEventDisplayAttributesByType[myStaffEvent.get('type')] || { icon: 'cirle-filled', color: 'grey' }
}

export function getStaffEventShortTitle(myStaffEvent: Map<string, any>) {
  switch (myStaffEvent.get('type')) {
    case 'timeOff':
    case 'block':
      if (myStaffEvent.get('timeOffAttributes')?.get('isPartial')) {
        return 'Partial Time Off'
      }
      return 'Time Off'
    case 'assignment':
    case 'onCall':
      return myStaffEvent.get('shiftName')
  }
  return 'Event'
}

export function getStaffEventLongTitle(myStaffEvent: Map<string, any>, unitsMap: Map<string, Map<string, any>>) {
  const titleComponents = []
  switch (myStaffEvent.get('type')) {
    case 'timeOff':
    case 'block':
      titleComponents.push(`Your ${getStaffEventShortTitle(myStaffEvent)}`)
      break
    case 'assignment':
    case 'onCall': {
      const unitName = unitsMap.get(myStaffEvent.get('unitId'))?.get('name')
      const roleName = myStaffEvent.get('unitRoleName')
      const shiftName = myStaffEvent.get('shiftName')
      titleComponents.push(unitName, roleName, shiftName)
      break
    }
    default:
      titleComponents.push('Event')
  }
  return titleComponents.join(' • ')
}

export function getStaffOnTimeOffStyle() {
  return staffEventDisplayAttributesByType['staffOnTimeOff']
}

export function convertDateFormat(date: string) {
  const [month, day, year] = date.split('-')
  return `${day}/${month}/${year}`
}

export const getFormattedTime = (val: string) => {
  // sh-time-picker in 12-hour format gives val as "12:35:PM", hence updating it to remove the trailing ':'
  const [h, m, amPm] = val.split(':')
  return `${h}:${m} ${amPm}`
}

export const getShiftTimeRange = (shift: Map<string, any>, timeService: any) => {
  const shiftStartMoment = timeService.timeMoment(null).set({
    hour: shift.get('startTime').split(':')[0],
    minute: shift.get('startTime').split(':')[1]
  })
  const shiftEndMoment = shiftStartMoment.clone().add(shift.get('length'), 'minutes')
  return timeService.formatTimeRange(shiftStartMoment, shiftEndMoment, 'hh:mm A')
}

export function getDurationMinutes(startTime: string | null, endTime: string, timeService: any) {
  if (startTime && endTime) {
    const endMoment = timeService._timeMoment(endTime, 'hh:mm A')
    const startMoment = timeService._timeMoment(startTime, 'hh:mm A')
    if (endMoment.isBefore(startMoment)) {
      endMoment.add(1, 'day')
    }

    return endMoment.diff(startMoment, 'minutes')
  }
  return 0
}

type FilterOptions = {
  [key: string]: string
}

export const filterOptions: FilterOptions = {
  assignment: 'Shift Assignment',
  timeOff: 'Time Off',
  partialTimeOff: 'Partial Time Off',
  shiftSwap: 'Shift Swap',
  staffOnTimeOff: 'Staff On Time Off'
}

type DefaultFilterOptionsBySection = {
  [key: string]: string[]
}
export const defaultFilterOptionsBySection: DefaultFilterOptionsBySection = {
  'shift-swaps': ['shiftSwap'],
  'shifts-assigned': ['assignment'],
  'time-offs': ['timeOff', 'partialTimeOff', 'staffOnTimeOff']
}

const opacity4 = 'var(--opacity-4)'

const colors = {
  tint_4: { rgb: 'var(--support-4-tint)', opacity: opacity4 },
  tint_1: { rgb: 'var(--support-1-tint)', opacity: opacity4 },
  tint_6: { rgb: 'var(--support-6-tint)', opacity: opacity4 },
  black: { rgba: 'rgba(var(--ui-1), var(--opacity-7))' }
}

export const statusDetails = {
  pendingFromStaff: { label: t('staffCalendar.shiftSwap.actionLabels.pendingFromStaff'), color: colors.tint_4 },
  pendingFromManager: { label: t('staffCalendar.shiftSwap.actionLabels.pendingFromManager'), color: colors.tint_4 },
  acceptedByStaff: { label: t('staffCalendar.shiftSwap.actionLabels.acceptedByStaff'), color: colors.tint_1 },
  approvedByManager: { label: t('staffCalendar.shiftSwap.actionLabels.approvedByManager'), color: colors.tint_1 },
  deniedByManager: { label: t('staffCalendar.shiftSwap.actionLabels.deniedByManager'), color: colors.tint_6 },
  declinedByStaff: { label: t('staffCalendar.shiftSwap.actionLabels.declinedByStaff'), color: colors.tint_6 },
  withdrawnByYou: { label: t('staffCalendar.shiftSwap.actionLabels.withdrawnByYou'), color: colors.black },
  withdrawnByStaff: { label: t('staffCalendar.shiftSwap.actionLabels.withdrawnByStaff'), color: colors.black },
  acceptedByYou: { label: t('staffCalendar.shiftSwap.actionLabels.acceptedByYou'), color: colors.tint_1 },
  pendingFromYou: { label: t('staffCalendar.shiftSwap.actionLabels.pendingFromYou'), color: colors.tint_4 },
  declinedByYou: { label: t('staffCalendar.shiftSwap.actionLabels.declinedByYou'), color: colors.tint_6 }
}

export interface StaffTimeOffRequestDateInput {
  date: string
  isPartial: boolean
  startTime: string | null
  duration: number
}

export interface StaffTimeOffRequestNoteInput {
  subject: string
}

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'error',
  WARNING: 'warning'
}
