import { Component } from 'react'
import { UpdateEntity } from '@humanics/he-react-common/lib/admin/components'
import { pick } from 'lodash'

export default class UpdateShift extends Component {
  constructor(props) {
    super(props)
    this.expertiseFields = ['licenseIds', 'certificationIds', 'skillIds', 'equipmentSkillIds']
    this.expertiseFieldsMap = {
      licenseIds: 'license',
      certificationIds: 'certification',
      skillIds: 'skill',
      equipmentSkillIds: 'equipment'
    }
    this.inputDefinitionExtraFields = {
      unitId: { disabled: true },
      groupId: { disabled: true },
      length: { disabled: true },
      startTime: { disabled: true },
      typeId: { disabled: true },
      type: {
        label: 'Type',
        inputType: 'InputDropdown',
        required: true,
        enum: [
          { id: 'day', title: 'Day' },
          { id: 'night', title: 'Night' }
        ],
        withLabel: true
      },

      licenseIds: {
        label: 'License',
        storeName: 'expertise',
        loadItemsParameters: {
          type: 'license'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'license'),
        items: {
          expertiseId: {
            label: 'License',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          }
        }
      },
      certificationIds: {
        label: 'Credentials/Certifications',
        storeName: 'expertise',
        loadItemsParameters: {
          type: 'certification'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'certification'),
        items: {
          expertiseId: {
            label: 'Credentials/Certifications',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          }
        }
      },
      skillIds: {
        label: 'Skill',
        storeName: 'expertise',
        loadItemsParameters: {
          type: 'skill'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'skill'),
        items: {
          expertiseId: {
            label: 'Select Skill',
            inputType: 'ExpertiseDropdown',
            withLabel: true
          },
          eligibleScaleIndexes: {
            label: 'Minimum Competence',
            inputType: 'ScaleItemsDropdown',
            withLabel: true,
            dependsOn: 'expertiseId'
          }
        }
      },
      equipmentSkillIds: {
        label: 'Equipment Skill',
        loadItemsParameters: {
          type: 'equipment'
        },
        inputType: 'InputExpertiseDynamicDropdown',
        value: (defaultValues) => this.getExpertiseValue(defaultValues, 'equipment'),
        items: {
          expertiseId: {
            label: 'Equipment + Skill',
            inputType: 'GroupedExpertiseDropdown',
            withLabel: true
          },
          eligibleScaleIndexes: {
            label: 'Minimum Competence',
            inputType: 'ScaleItemsDropdown',
            withLabel: true,
            dependsOn: 'expertiseId'
          }
        }
      }
    }

    this.state = {
      apiInitialized: false,
      expertiseItems: []
    }
  }

  componentDidMount() {
    const { stores } = this.props

    ;(async () => {
      const store = stores.get('expertise')
      await store.getApi()
      const { stateKey } = store.getAdminInfo()
      store.loadDataAction().then((state) => {
        const expertiseItems = state.getIn([stateKey, 'list'])
        this.setState({ apiInitialized: true, expertiseItems })
      })
    })()
  }

  getExpertiseValue = (defaultValues, type) => {
    const { expertiseItems } = this.state
    const values = defaultValues['expertiseRequirements'] || []
    const expertiseIds = expertiseItems.filter((item) => item.type === type).map((item) => item.id)
    return values.filter((item) => expertiseIds.includes(item.expertiseId))
  }

  render() {
    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }
    const updateEntityProps = {
      ...this.props,
      inputDefinitionExtraFields: this.inputDefinitionExtraFields
    }

    return <UpdateEntity {...updateEntityProps} onSubmit={this.onSubmit} />
  }

  prepareExpertise = (form) => {
    let expertiseRequirements = []
    this.expertiseFields.forEach((field) => {
      const data = form[field] || []
      const availableFields = Object.keys(this.inputDefinitionExtraFields[field].items)
      const filteredData = data.map((item) => pick(item, availableFields))
      expertiseRequirements = [...expertiseRequirements, ...filteredData]
    })
    return expertiseRequirements
  }

  onSubmit = (e, form) => {
    const { store, history, match } = this.props
    const updateOperationId = 'updateShift'
    const { length, startTime, ...rest } = form
    const expertiseRequirements = this.prepareExpertise(form)
    const params = {
      ...rest,
      expertiseRequirements
    }
    const { id } = match.params

    return store
      .update(
        {
          updateOperationId,
          form: params
        },
        id
      )
      .then((data) => history.push('.'))
  }
}
