import { Component, createRef } from 'react'
import { Map } from 'immutable'
import OpenShiftStatus from '../../OpenShiftCards/WeekOpenShifts/OpenShift/OpenShiftStatus'
import { OpenShiftEntity } from '../../../../entityWrappers'
import { SwitchInput } from '../../../components'

export default class ShiftDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { cursor: null }
    this.textarea = createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    const { cursor } = this.state
    if (cursor) {
      this.textarea.current.selectionEnd = cursor
    }
  }

  render() {
    const { openShiftController, timeService } = this.props
    const { openShiftParameters } = openShiftController
    const { isImportant } = openShiftParameters
    const openShift = this.props.openShift || Map()
    const unit = this.props.unit || Map()

    const unitName = unit.get('name') || null
    const dayStartTime = unit.get('dayStartTime') || null
    const nightStartTime = unit.get('nightStartTime') || null
    const dayStartTimeMoment = timeService.timeMoment(dayStartTime, 'HH:mm')
    const nightStartTimeMoment = timeService.timeMoment(nightStartTime, 'HH:mm')

    const shiftStartsAt = openShift.get('shiftStartsAt') || null
    const shiftEndsAt = openShift.get('shiftEndsAt') || null
    const shiftStartsAtMoment = timeService.timeMoment(shiftStartsAt)
    const shiftEndsAtAtMoment = timeService.timeMoment(shiftEndsAt)

    const shiftDate = shiftStartsAtMoment.format('MMM DD, YYYY')
    const start = shiftStartsAtMoment.format('HH:mm') || ''
    const end = shiftEndsAtAtMoment.format('HH:mm') || ''
    const time = start && end && `${start}-${end}`
    const dayTime = timeService
      .timeMoment(start, 'HH:mm')
      .isBetween(dayStartTimeMoment, nightStartTimeMoment, null, '[)')
      ? 'Day'
      : 'Night'

    const roleId = openShift.get('unitRoleId')
    const role = unit.get('roles').find((g) => g.get('id') === roleId)
    const roleName = role?.get('name') || '-'
    const openShiftEntity = new OpenShiftEntity(this.props.openShift)
    const { staffMismatch, isPosted, pendingToConfirmUserIds, isFilled } = openShiftEntity
    const openShiftStatusProps = {
      isPosted,
      pendingToConfirmUserIds,
      isFilled,
      openShift,
      staffMismatch,
      columnLabel: false
    }
    const importantProps = {
      value: isImportant === true,
      disabled: isPosted,
      setConfig: openShiftController.setIsImportant,
      fieldName: 'Mark as Important',
      field: 'important',
      switchClasses: 'item-group'
    }
    return (
      <section>
        <div className="row pb10">
          <h3 className="semibold">Shift Details</h3>
        </div>

        <div className="hx-open-shift-request-shift-details bg-white rounded">
          <div className="details-item align-right">
            <SwitchInput {...importantProps} />
          </div>
          <div className="details-item">
            <h3 className="title">
              <div>{unitName}</div>
            </h3>
          </div>

          <div className="details-item">
            <div className="status">
              <OpenShiftStatus {...openShiftStatusProps} />
            </div>
          </div>
          <div className="details-item">
            <div className="item-group gap-1">
              <div className="details-item">
                <div className="pr20">
                  <div className="upper tiny black regent-gray">Shift Date</div>
                </div>
                <div className="big">{shiftDate}</div>
              </div>

              <div className="details-item">
                <div className="pr20">
                  <div className="upper tiny black regent-gray">Shift</div>
                </div>
                <div className="big">{dayTime}</div>
              </div>

              <div className="details-item">
                <div className="pr20">
                  <div className="upper tiny black regent-gray">Time</div>
                </div>
                <div className="big">{time}</div>
              </div>

              <div className="details-item">
                <div className="pr20">
                  <div className="upper tiny black regent-gray">Role</div>
                </div>
                <div className="big">{roleName}</div>
              </div>
            </div>
          </div>
          <div className="details-item">
            <div>
              <div className="upper tiny black regent-gray">shift note</div>
            </div>
            <div className="form-item item-group gap-1">
              <textarea
                ref={this.textarea}
                placeholder="Optional"
                className="rounded"
                disabled={true}
                name="note"
                value={''}
                onChange={this.onChangeTextarea}
                maxLength={MAX_NOTE_LENGTH}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
  onChangeTextarea = (e) => {
    e.persist()
    /* Uncomment when feature is available
    const { openShiftController } = this.props;
    const { selectionEnd: cursor } = e.target;
    this.setState({ cursor });

    openShiftController.onInputChange('note')(e);
    */
  }
}

const MAX_NOTE_LENGTH = 1000
