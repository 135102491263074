import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [
  Field('name', 'Shift'),
  Field('unitName', 'Unit'),
  Field('groupName', 'Roles'),
  Field('startTime', 'Start Time'),
  Field('updatedAt', 'Modified')
]

function ShiftStore() {
  const store = AdminStore({
    fields,
    entityName: 'Shift',
    api: {
      index: 'indexShifts'
    },
    header: { title: 'Shifts' }
  })

  function loadShifts() {
    return store.loadDataAction()
  }

  return {
    ...store,
    loadShifts,
    hasCrud: (operation, ...args) => {
      if (operation === 'delete') {
        return false
      }
      return store.hasCrud(operation, ...args)
    }
  }
}

export default ShiftStore
