import queryString from 'query-string'
import { navigate } from 'Navigation'
import EquipmentCreateUpdate from './EquipmentCreateUpdate'
import { merge } from 'lodash'

export default class EquipmentCreate extends EquipmentCreateUpdate {
  onFormSubmit = async (event, form) => {
    const {
      match,
      location: { search: url }
    } = this.props
    const { groupId } = queryString.parse(url)
    const { urlId } = match.params
    if (groupId) {
      await this.controller.createEquipment(form)
    } else {
      await this.controller.createEquipmentGroup(form)
    }
    this.controller.loadData()
    return navigate.to.AdminEquipments({ urlId })
  }

  render() {
    const { isEquipmentsLoaded } = this.controller
    if (!isEquipmentsLoaded) {
      return null
    }
    const { store, stores, history, appState } = this.props
    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }

    const isStoreApiInitialized = store.isApiInitialized(appState)
    if (!isStoreApiInitialized) {
      return null
    }

    const url = this.props.location.search
    const { groupId } = queryString.parse(url)
    let inputDefinition = store.getInputDefinition('ExpertiseGroup')
    const entityType = groupId ? 'Equipment' : 'EquipmentGroup'
    const title = groupId ? 'Add Equipment' : 'Create Category'
    let defaultValues = {}
    if (groupId) {
      const { properties } = inputDefinition
      const inputDefinitionExtraFields = {
        parentId: {
          formItemClasses: 'header',
          enum: this.controller.equipmentGroups,
          required: true
        },
        title: {
          formItemClasses: 'top-sidebar',
          required: true
        },
        description: {
          formItemClasses: 'bottom-sidebar',
          required: true
        },
        imageUrl: {
          formItemClasses: 'image'
        },
        skills: {
          type: 'array',
          formItemClasses: 'footer'
        }
      }
      inputDefinition = { properties: merge({}, properties, inputDefinitionExtraFields) }
      defaultValues = { ...defaultValues, parentId: groupId }
    }

    return this.renderModal({
      entityType,
      inputDefinition,
      defaultValues,
      stores,
      history,
      appState,
      title,
      groupId
    })
  }
}
