// @ts-ignore
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { List, Map } from 'immutable'
import { shiftDayAggregatesByShiftQuery, sortedStaffOvertimesQuery } from './Queries'

interface IShiftDayAggregateByShift {
  totalStaffMismatch: number
  totalStaffRequirement: number
  shiftId: string
}
export type IShiftDayAggregate = Map<string, number>
type IShiftDayAggregatesByShiftId = Map<string, IShiftDayAggregate>
interface IShiftDayAggregatesByShiftQueryParameters {
  startDate: string
  endDate: string
  shiftIds?: string[]
  unitId?: string
}

export default function DashboardStore() {
  const defaultState = Map({
    shiftDayAggregatesByShiftId: Map(),
    sortedStaffOvertimes: List()
  })

  const actions = fluxStore({ loadShiftDayAggregatesByShift, fetchSortedStaffOvertimes })
  let gqlClient: any = null
  return { initialize, ...actions }

  function initialize(state: any, context: any) {
    gqlClient = context.gqlClient
    return state.set('dashboard', defaultState)
  }

  async function loadShiftDayAggregatesByShift(
    state: any,
    startDate: string,
    endDate: string,
    unitId?: string,
    shiftIds?: string[]
  ) {
    const parameters: IShiftDayAggregatesByShiftQueryParameters = {
      startDate,
      endDate
    }
    if (shiftIds) {
      parameters.shiftIds = shiftIds
    } else {
      parameters.unitId = unitId
    }
    const { shiftDayAggregatesByShift } = await gqlClient.query(shiftDayAggregatesByShiftQuery, parameters)
    const shiftDayAggregatesByShiftId: IShiftDayAggregatesByShiftId = shiftDayAggregatesByShift.reduce(
      (acc: IShiftDayAggregatesByShiftId, shiftDayAggregateByShift: IShiftDayAggregateByShift) => {
        const { shiftId, totalStaffMismatch, totalStaffRequirement } = shiftDayAggregateByShift
        return acc.set(shiftId, Map({ totalStaffMismatch, totalStaffRequirement }))
      },
      Map({})
    )
    return state.setIn(['dashboard', 'shiftDayAggregatesByShiftId'], shiftDayAggregatesByShiftId)
  }

  async function fetchSortedStaffOvertimes(state: any, unitId: string, startDate: string, endDate: string) {
    const parameters: any = {
      unitId,
      startDate,
      endDate
    }
    const { staffOvertimes: sortedStaffOvertimes } = await gqlClient.query(sortedStaffOvertimesQuery, parameters)
    return state.setIn(['dashboard', 'sortedStaffOvertimes'], List(sortedStaffOvertimes))
  }
}
