import { t } from 'i18n'

const form = {
  firstName: {
    definition: {
      label: 'Display First Name',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'firstName',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['facilityProfile', 'firstName']) || '',
    validate: (...parameters) => validateField('firstName', ...parameters)
  },
  lastName: {
    definition: {
      label: 'Display Last Name',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'lastName',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['facilityProfile', 'lastName']) || '',
    validate: (...parameters) => validateField('lastName', ...parameters)
  },
  email: {
    definition: {
      label: 'Email',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'email',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => {
      const hasPermissions = staff.get('hasPermissions')
      const email = hasPermissions ? staff.getIn(['profile', 'email']) : staff.getIn(['facilityProfile', 'email'])
      return email || ''
    },
    validate: (...parameters) => validateField('email', ...parameters)
  },
  phoneNumber: {
    definition: {
      label: 'phone',
      labelDescription: '(Invitation number)',
      type: 'string',
      withLabel: true,
      minLength: 12,
      maxLength: 15
    },
    field: 'phoneNumber',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => {
      const hasPermissions = staff.get('hasPermissions')
      const phoneNumber = hasPermissions
        ? staff.getIn(['profile', 'phoneNumber'])
        : staff.getIn(['facilityProfile', 'phoneNumber'])
      return phoneNumber || ''
    },
    validate: (...parameters) => validateField('phoneNumber', ...parameters)
  },
  phoneNumber1: {
    definition: {
      label: 'cell phone',
      required: false,
      type: 'string',
      withLabel: true,
      minLength: 9,
      maxLength: 15
    },
    field: 'phoneNumber1',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'phoneNumber1']) || '',
    validate: (...parameters) => validateField('phoneNumber1', ...parameters)
  },
  phoneNumber2: {
    definition: {
      label: 'work phone',
      required: false,
      type: 'string',
      withLabel: true,
      minLength: 9,
      maxLength: 15
    },
    field: 'phoneNumber2',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'phoneNumber2']) || '',
    validate: (...parameters) => validateField('phoneNumber2', ...parameters)
  },
  startsAt: {
    definition: {
      label: 'start date',
      required: true,
      type: 'date',
      dependsOn: false,
      disableFunc: (isEdit) => !!isEdit
    },
    dateFormatHint: 'YYYY-MM-DD',
    field: 'startsAt',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'startsAt']) || '',
    validate: (...parameters) => validateField('startsAt', ...parameters)
  },
  endsAt: {
    definition: {
      label: 'end date',
      required: false,
      type: 'date',
      withLabel: true,
      dependsOn: false
    },
    dateFormatHint: 'YYYY-MM-DD',
    field: 'endsAt',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'endsAt']) || '',
    validate: (...parameters) => validateField('endsAt', ...parameters)
  },
  managerFullName: {
    definition: {
      label: 'manager',
      required: false,
      type: 'string',
      withLabel: true
    },
    field: 'managerFullName',
    formItemClasses: 'col-12 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['facilityProfile', 'managerFullName']) || '',
    validate: (...parameters) => validateField('managerFullName', ...parameters)
  },
  oldHomeUnitId: {
    definition: {
      label: 'Home Unit',
      required: false,
      type: 'string',
      withLabel: true,
      disabled: true
    },
    field: 'oldHomeUnitId',
    formItemClasses: 'col-4 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.get('homeUnitId') || '',
    validate: (...parameters) => validateField('oldHomeUnitId', ...parameters)
  },
  position: {
    definition: {
      label: 'Position',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'position',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'position']) || '',
    validate: (...parameters) => validateField('position', ...parameters)
  },
  homeUnitId: {
    definition: {
      label: 'Department',
      required: true,
      type: 'string',
      withLabel: true
    },
    field: 'homeUnitId',
    formItemClasses: 'col-6 pr30',
    entityType: 'FacilityUserInput',
    validate: (...parameters) => validateField('homeUnitId', ...parameters)
  },
  employmentType: {
    definition: {
      label: 'Employment Type',
      customPlaceHolder: '-- Select Type --',
      required: true,
      type: 'string',
      withLabel: true,
      items: {
        enum: [
          { key: 'fullTime', name: 'Full Time' },
          { key: 'partTime', name: 'Part Time' },
          { key: 'perDiem', name: 'Per Diem' }
        ]
      }
    },
    field: 'employmentType',
    formItemClasses: 'col-4 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'employmentType']) || '',
    validate: (...parameters) => validateField('employmentType', ...parameters)
  },
  maximumNumberOfHoursPerWeek: {
    definition: {
      label: 'Hours Per Week',
      required: false,
      type: 'number',
      withLabel: true,
      step: 0.25
    },
    field: 'maximumNumberOfHoursPerWeek',
    formItemClasses: 'col-4 pr30',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'maximumNumberOfHoursPerWeek']) || '',
    validate: (...parameters) => validateField('maximumNumberOfHoursPerWeek', ...parameters)
  },
  availabilityType: {
    definition: {
      label: 'Availability Type',
      customPlaceHolder: '-- Select Type --',
      required: true,
      type: '',
      withLabel: true,
      items: {
        enum: [
          { key: 'dayTime', name: 'Day Time' },
          { key: 'nightTime', name: 'Night Time' },
          { key: 'anyTime', name: 'Any Time' }
        ]
      }
    },
    formItemClasses: 'col-4 pr30',
    field: 'availabilityType',
    entityType: 'FacilityUserInput',
    path: (staff) => staff.getIn(['staffProfile', 'availabilityType']) || '',
    validate: (...parameters) => validateField('availabilityType', ...parameters)
  }
}

const isValidEmail = (email) => {
  const rgex =
    /^(([^<>() [\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/
  return email && rgex.test(email)
}

const isValidPhoneNumber = (phoneNumber) => {
  const rgex = /^([+]\D?(\d{10,15})|[+]1)$/
  return phoneNumber && rgex.test(phoneNumber)
}

const validateField = (field, form, options = {}) => {
  const { showError = true, isEdit } = options
  let { value, inlineError } = form[field]
  let { required } = form[field].definition

  let isValid = true
  const hasInlineError = !!inlineError

  switch (true) {
    case field === 'homeUnitId' && isEdit:
      break
    case value && field === 'email':
      isValid = !hasInlineError && isValidEmail(value)
      inlineError = inlineError || (!isValid && t('auth.invalid_email'))
      break

    case value && ['phoneNumber', 'phoneNumber1', 'phoneNumber2'].includes(field):
      isValid = !hasInlineError && isValidPhoneNumber(value)
      inlineError = inlineError || (!isValid && t('auth.invalid_phone'))
      break

    case required:
      isValid = !hasInlineError && !!value
      inlineError = inlineError || (!isValid && t('auth.required_field'))
      break

    default:
  }

  if (showError) {
    form[field].inlineError = inlineError
  }

  return isValid
}

export default form
