import { PureComponent } from 'react'
import { RolesSelector, ShiftTypesSelector } from 'Manager/components'
import IndicatorsSelector from './IndicatorsSelector'
import SelectMode from './SelectMode'
import ActiveDateRange from 'Navigation/ActiveDateRange'
import SearchInput from 'components/Form/SearchInput'
import { debounce } from 'lodash'
import Navigation from '../Navigation'
import DisplayOptions from './DisplayOptions'
import DisplayKpis from './DisplayKpis/DisplayKpis'
import { fromJS } from 'immutable'
import { t } from 'i18n'
import AutoscheduleModal from 'components/Form/AutoScheduleModal/AutoScheduleModal'
import { navigate } from 'Navigation'
import ResetPopup from '../../../components/Form/AutoScheduleModal/ResetPopup/ResetPopup'

export default class Toolbar extends PureComponent {
  state = { isNavigationShown: false, search: '', isAutoScheduleShown: false, windowWidth: window.innerWidth }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)
  }

  render() {
    const {
      mode,
      isEnabledFeature,
      filters,
      updateFilter,
      activeDateRange,
      unit,
      onChangeDisplayOptions,
      authentication,
      onChangeViewPreference,
      getUnitManagerViewPreferences,
      usDate,
      timeService,
      algoScheduleStore
    } = this.props
    const { isAutoScheduleShown } = this.state
    const autoScheduleFeatureState = authentication
      .getIn(['facility', 'configuration', 'features'])
      .find((feature) => feature.get('name') === 'auto_schedule')
      ?.get('state')

    const isAutoScheduleEnabled = ['read', 'write'].includes(autoScheduleFeatureState)
    // const setIsAutoSchedule=isAutoScheduleShown=()=>{
    //   this.setState({isAutoScheduleShown:true})
    // }
    const kpiFeatureState = authentication
      .getIn(['facility', 'configuration', 'features'])
      .find((feature) => feature.get('name') === 'kpis')
      ?.get('state')

    const isKpisEnabled = ['read', 'write'].includes(kpiFeatureState)
    const roles = unit.get('roles')
    const unitShiftTypes = unit.get('shiftTypes')
    const displayOptions = filters.get('displayOptions')
    const viewPreferences = getUnitManagerViewPreferences()

    const selectModeProps = { mode, activeDateRange, unitId: unit.get('id'), onChangeViewPreference, viewPreferences }

    const usDateMoment = timeService.usDateToMoment(usDate)
    const prevUsWeekMoment = usDateMoment.clone().subtract(1, 'week')
    const nextUsWeekMoment = usDateMoment.clone().add(1, 'week')

    const getStartOfMonth = (date) => date.clone().startOf('month')
    const getEndOfMonth = (date) => date.clone().endOf('month')

    const currentMonthStart = getStartOfMonth(usDateMoment)
    const currentMonthEnd = getEndOfMonth(usDateMoment)

    const prevMonthEnd = getEndOfMonth(usDateMoment.clone().subtract(1, 'month'))

    const nextMonthStart = getStartOfMonth(usDateMoment.clone().add(1, 'month'))

    const prevWeekUrl = prevUsWeekMoment.isBefore(currentMonthStart)
      ? navigate.from.Calendar.to.Calendar({ date: prevMonthEnd.format('MM-DD-YYYY'), mode: 'week' }, false)
      : navigate.from.Calendar.to.Calendar({ date: prevUsWeekMoment.format('MM-DD-YYYY'), mode: 'week' }, false)

    const nextWeekUrl = nextUsWeekMoment.isAfter(currentMonthEnd)
      ? navigate.from.Calendar.to.Calendar({ date: nextMonthStart.format('MM-DD-YYYY'), mode: 'week' }, false)
      : navigate.from.Calendar.to.Calendar({ date: nextUsWeekMoment.format('MM-DD-YYYY'), mode: 'week' }, false)

    const activeDateRangeProps = {
      showStepper: mode === 'week',
      stepperLeftPath: prevWeekUrl,
      stepperRightPath: nextWeekUrl,
      usDate
    }

    const displayOptionsProps = {
      selectOptions: getStaffDisplaySelectOptions(displayOptions),
      minWidth: 100,
      values: displayOptions,
      setConfig: onChangeDisplayOptions,
      field: 'sort'
    }

    const rolesSelectorProps = {
      roles,
      values: filters.get('roleIds'),
      setRole: (value) => updateFilter((filters) => filters.set('roleIds', value))
    }

    const shiftTypesSelectorProps = {
      shiftTypes: unitShiftTypes,
      values: filters.get('shiftTypes'),
      setShiftType: (value) => updateFilter((filters) => filters.set('shiftTypes', value))
    }

    const isScheduleInBalancingState = activeDateRange.get('scheduleState') === 'balancing'
    const isSmallViewport = this.state.windowWidth < 1900

    const isAutoScheduleButtonShown = isAutoScheduleEnabled && isScheduleInBalancingState
    const isKpiButtonShown = isKpisEnabled && isScheduleInBalancingState
    // const isAutoScheduleOrKpiButtonShown = isAutoScheduleButtonShown || isKpiButtonShown

    return (
      <div className="row align-middle calendar-toolbar">
        <div className="col-4 row">
          <div className="col flex-end-align br1">
            <SelectMode {...selectModeProps} />
          </div>
          <div className="filter-container space-around-align br1">
            {!isSmallViewport ? (
              <>
                <span className="river-bad">
                  <ShiftTypesSelector {...shiftTypesSelectorProps} />
                </span>
                <span className="river-bad">
                  <RolesSelector {...rolesSelectorProps} />
                </span>
              </>
            ) : (
              <span className="pr10">
                <sh-icon color="" icon="filter" id="filter-popup" size="s" button={true} />
              </span>
            )}
          </div>
          <div className="col filter-container hx-filters br1 pr10">
            <IndicatorsSelector
              indicators={filters.get('indicators')}
              updateIndicator={this.props.updateFilterRole('indicators')}
            />
          </div>

          <sh-popover target="filter-popup" position="bottom-left" sticky={true}>
            <div className="filter-container space-around-align">
              <span className="river-bad">
                <ShiftTypesSelector {...shiftTypesSelectorProps} />
              </span>

              <span className="river-bad">
                <RolesSelector {...rolesSelectorProps} />
              </span>
            </div>
          </sh-popover>

          {isEnabledFeature('calendar-staff-display-settings') && <DisplayOptions {...displayOptionsProps} />}
        </div>

        <div className={`${mode === 'full' ? 'col-3' : 'col-4'} row align-center`}>
          <ActiveDateRange {...activeDateRangeProps} />
        </div>

        <div className={`${mode === 'full' ? 'col-4' : 'col-3'} row align-right align-middle`}>
          {isAutoScheduleButtonShown && (
            <sh-button
              label="Auto Scheduler"
              color="secondary"
              class="auto-scheduler-button"
              size="xs"
              onClick={() => this.onAutoSchedulerButtonClick()}
            ></sh-button>
          )}
          {isAutoScheduleEnabled && isScheduleInBalancingState && (
            <ResetPopup algoScheduleStore={algoScheduleStore} unit={unit}></ResetPopup>
          )}
          {isKpiButtonShown && <DisplayKpis calendarStore={this.props.calendarStore} unit={unit} />}
          <div className="col">
            <Navigation {...this.props} />
          </div>
        </div>

        <div className="col-1 hx-staff-filter-input bl1">
          <SearchInput
            onChange={this.onChange}
            onReset={this.onReset}
            value={this.state.search}
            usDate={this.props.usDate}
            urlId={this.props.urlId}
            iconStyle={{ left: '-2px', top: '1em' }}
          />
        </div>

        {isAutoScheduleShown && (
          <AutoscheduleModal cancelClick={this.onAutoSchedulerCancelButtonClick} {...this.props} />
        )}
      </div>
    )
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  updateSearch = debounce((value) => {
    this.props.updateFilter((filters) => filters.set('filterBySearch', value))
  }, 300)

  handleWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  onChange = (e) => {
    const { value } = e.target
    this.setState({ search: value })
    this.updateSearch(value)
  }

  onAutoSchedulerButtonClick = () => {
    this.setState({ isAutoScheduleShown: true })
  }

  onAutoSchedulerCancelButtonClick = () => {
    this.setState({ isAutoScheduleShown: false })
  }

  onReset = () => {
    this.onChange({ target: { value: '' } })
  }

  setIndicatorsConfig = (indicators) => {
    this.props.setConfig({ indicators })
  }

  setShiftTypesConfig = (event) => {
    this.props.setConfig({ shiftType: event.target.value })
  }

  setRolesFilterConfig = (event) => {
    this.props.setConfig({ roleId: event.target.value })
  }

  showDateRange = () => {
    this.setState({ isNavigationShown: true })
  }

  hideDateRange = () => {
    this.setState({ isNavigationShown: false })
  }
}

const getStaffDisplaySelectOptions = (displayOptions) => {
  const sortBy = displayOptions.getIn(['staff', 'sortBy'])

  const staff = [
    {
      label: 'Display Name As',
      field: 'displayNameAs',
      items: {
        enum: fromJS([
          { key: 'firstNameLastName', name: 'Jane Smith' },
          { key: 'lastNameFirstName', name: 'Smith, Jane' }
        ])
      }
    },
    {
      label: 'Group By',
      field: 'groupBy',
      items: {
        enum: fromJS([
          { key: 'none', name: 'None' },
          { key: 'employment', name: 'Employment' }
        ])
      }
    },
    {
      label: 'Sort By',
      field: 'sortBy',
      items: {
        enum: fromJS([
          { key: 'firstName', name: 'First Name' },
          { key: 'lastName', name: 'Last Name' },
          { key: 'hireDate', name: 'Hire Date' }
        ])
      }
    },
    {
      label: 'Order By',
      field: 'orderBy',
      items: {
        enum: fromJS([
          { key: 'asc', name: sortBy !== 'hireDate' ? 'A to Z' : 'Earliest to Most Recent' },
          { key: 'desc', name: sortBy !== 'hireDate' ? 'Z to A' : 'Most Recent to Earliest' }
        ])
      }
    }
  ]

  const coverage = [
    {
      label: 'View Coverage As',
      field: 'viewCoverageAs',
      items: {
        enum: fromJS([
          {
            key: 'scheduledStaff',
            name: 'Scheduled Staff',
            description: t('calendar.display_options.scheduledStaff')
          },
          {
            key: 'staffDelta',
            name: 'Staff Delta',
            description: t('calendar.display_options.staffDelta')
          }
        ])
      }
    }
  ]

  return { staff, coverage }
}
