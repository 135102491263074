import { Component } from 'react'
import { CreateEntity } from '@humanics/he-react-common/lib/admin/components'
import { pick } from 'lodash'

export default class CreateShift extends Component {
  render() {
    const createProps = {
      ...this.props,
      inputDefinitionExtraFields
    }

    return <CreateEntity {...createProps} onSubmit={this.onSubmit} />
  }

  prepareExpertise = (form) => {
    let expertiseRequirements = []
    expertiseFields.forEach((field) => {
      const data = form[field] || []
      const availableFields = Object.keys(inputDefinitionExtraFields[field].items)
      const filteredData = data.map((item) => pick(item, availableFields))
      expertiseRequirements = [...expertiseRequirements, ...filteredData]
    })
    return expertiseRequirements
  }

  onSubmit = (e, form) => {
    const { store, history } = this.props
    const createOperationId = 'createShift'
    const expertiseRequirements = this.prepareExpertise(form)
    const unit = {
      ...form,
      expertiseRequirements
    }
    return store.create({ createOperationId, form: unit }).then((data) => history.push('.'))
  }
}
const expertiseFields = ['licenseIds', 'certificationIds', 'skillIds', 'equipmentSkillIds']

const inputDefinitionExtraFields = {
  unitId: {
    label: 'Unit',
    inputType: 'InputDropdownUnits',
    model: 'unit'
  },
  type: {
    label: 'Type',
    inputType: 'InputDropdown',
    required: true,
    enum: [
      { id: 'day', title: 'Day' },
      { id: 'night', title: 'Night' }
    ],
    withLabel: true
  },
  typeId: {
    label: 'Shift type',
    inputType: 'InputDropdownShiftTypes',
    default: [],
    withLabel: true
  },
  licenseIds: {
    label: 'License',
    loadItemsParameters: {
      type: 'license'
    },
    inputType: 'InputExpertiseDynamicDropdown',
    default: [],
    items: {
      expertiseId: {
        label: 'License',
        inputType: 'ExpertiseDropdown',
        withLabel: true
      }
    }
  },
  certificationIds: {
    label: 'Credentials/Certifications',
    loadItemsParameters: {
      type: 'certification'
    },
    inputType: 'InputExpertiseDynamicDropdown',
    default: [],
    items: {
      expertiseId: {
        label: 'Credentials/Certifications',
        inputType: 'ExpertiseDropdown',
        withLabel: true
      }
    }
  },
  skillIds: {
    label: 'Skill',
    loadItemsParameters: {
      type: 'skill'
    },
    inputType: 'InputExpertiseDynamicDropdown',
    default: [],
    items: {
      expertiseId: {
        label: 'Select Skill',
        inputType: 'ExpertiseDropdown',
        withLabel: true
      },
      eligibleScaleIndexes: {
        label: 'Minimum Competence',
        inputType: 'ScaleItemsDropdown',
        withLabel: true,
        dependsOn: 'expertiseId'
      }
    }
  },
  equipmentSkillIds: {
    label: 'Equipment Skill',
    loadItemsParameters: {
      type: 'equipment'
    },
    inputType: 'InputExpertiseDynamicDropdown',
    default: [],
    items: {
      expertiseId: {
        label: 'Equipment + Skill',
        inputType: 'GroupedExpertiseDropdown',
        withLabel: true
      },
      eligibleScaleIndexes: {
        label: 'Minimum Competence',
        inputType: 'ScaleItemsDropdown',
        withLabel: true,
        dependsOn: 'expertiseId'
      }
    }
  }
}
