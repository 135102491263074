import { Component } from 'react'
import { pick } from 'lodash'
import StaffList from './StaffList'

export default class OpenShiftStaff extends Component {
  constructor(props) {
    super(props)
    this.state = {
      requiredStaff: 0,
      isLoading: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { openShift: prevOpenShift } = prevProps
    const { openShift } = this.props
    const prevRequiredStaff = prevOpenShift
      ? prevOpenShift.get('requiredStaff') || prevOpenShift.get('staffRequired')
      : 0
    const requiredStaff = openShift ? openShift.get('requiredStaff') || openShift.get('staffRequired') : 0
    const isStaffRequirementsChanged = requiredStaff !== prevRequiredStaff
    if (openShift && isStaffRequirementsChanged) {
      this.setState({ requiredStaff })
    }
  }

  render() {
    const { openShiftController, openShiftStaffMap } = this.props
    const { openShift } = openShiftController
    const { isPosted } = openShift

    if (!openShiftStaffMap || openShiftStaffMap.size === 0) {
      return null
    }

    const groupTypes = isPosted
      ? ['approvedStaff', 'acceptedStaff', 'notRespondedStaff', 'notInvitedStaff']
      : ['approvedStaff', 'eligibleStaff']

    return (
      <>
        {groupTypes.map((type, index) => {
          const staffListProps = pick(
            {
              ...this.props,
              ...this.state,
              type,
              isGroupCollapsed: defaultGroupsCollapse[type]
            },
            [
              'openShiftController',
              'openShiftStaffMap',
              'isGroupCollapsed',
              'requiredStaff',
              'type',
              'openShift',
              'isLoading',
              'roles',
              'unitShiftTypes',
              'eligibleUnits',
              'timeService',
              'isPosted',
              'expertises'
            ]
          )

          return <StaffList {...staffListProps} key={index} />
        })}
      </>
    )
  }
}

const defaultGroupsCollapse = {
  acceptedStaff: false,
  notRespondedStaff: false,
  notInvitedStaff: true,
  eligibleStaff: false
}
