import { SpillProofDiv } from 'Common/components'
import './Shift.scss'

function VUnitTitle({ unit }) {
  return (
    <aside className="col-4 row align-middle flex-nowrap">
      <span className="upper smaller bold river-bad col-10 pr10">
        <section>
          <SpillProofDiv id={unit.get('id')} tooltipPosition="right" hasCustomTooltip={true}>
            {`"${unit.get('name')}" Unit`}
          </SpillProofDiv>
        </section>
      </span>
    </aside>
  )
}
export default VUnitTitle
